/* --- PRODUCT CREDITS JURIDIC CONTENT --- */
@import '../../../styles/settings/colors';

.product-credits-juridic-content {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  .wrapper {
    padding: 0 80px;
    text-align: left;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-juridic-content {
    .wrapper {
      padding: 0 30px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .product-credits-juridic-content {
    .wrapper {
      padding: 0;
    }
  }
}