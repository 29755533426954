/* --- READ MORE --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

@import '../../styles/tools/mixins';
@import '../../styles/components/animations';
@import '../../styles/components/forms/buttons';
@import '../../styles/components/typography';

.read-more {
  align-items: flex-end;
  background-color: $red-400;
  display: inline-flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  float: left;
  margin: 0;
  position: relative;
  transition: all 400ms $base-ease;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--image {
    background-color: $red-900;
    float: left;
    position: relative;
    overflow: hidden;
    transition: all 400ms $base-ease;
    width: calc(50% + 75px);
    vertical-align: top;
    z-index: 1;

    picture {
      float: left;
      height: 100%;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    img {
      float: left;
      height: auto;
      left: -5px;
      position: relative;
      width: calc(100% + 25px);
      z-index: 9;
    }

    &:before {
      background-color: inherit;
      content: '';
      left: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      transition: all 400ms $base-ease;
      width: calc(100vw - #{$page-width} / 2);
      z-index: 1;
    }
  }

  .wrapper {
    display: inline-flex;
  }

  &:before {
    background-color: $red-900;
    content: '';
    height: 175px;
    left: 0;
    position: absolute;
    top: 100%;
    width: calc(((100% - 30px) - #{$page-width}) / 2);
  }

  &[data-type="tipo2"] {
    background-color: $green-600;

    .read-more {
      &--image {
        background-color: $green-900;
      }
    }

    &:before {
      background-color: $green-600;
      bottom: initial;
      top: 0;
      transform: translateY(-100%);
    }

    & + .photo-special {
      .photo-special--block {
        svg {
          .block-2 {
            fill: $purple-900;
          }
        }
      }
    }
  }

  &[data-type="tipo3"] {
    background-color: $purple-900;

    .read-more {
      &--image {
        background-color: $purple-900;
      }

      &--info {
        background-color: $purple-900;

        &--content {
          background-color: $purple-900;
        }
      }
    }

    &:before {
      background-color: $purple-900;
      bottom: initial;
      top: 0;
      transform: translateY(-100%);
    }
  }

  & + div {
    z-index: 1;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .read-more {
    display: inline-block;

    &--image {
      right: 40px;
      height: 100%;
      padding: 0;
      position: absolute;
      top: 0;
      width: calc(50% - 70px);
      
      img {
        object-fit: cover;
        left: 0;
        height: 100%;
        width: 100%;
      }

      &:before {
        width: 40px;
      }
    }

    &:before {
      width: 40px;
    }

    &[data-type="tipo2"] {
      &:before {
        display: none;
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 640px) {
  .read-more {
    display: inline-flex;

    &--image {
      max-width: initial;
      order: 1;
      height: 190px;
      right: 0;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }

    .wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      padding: 0;
    }

    &:before {
      display: none;
    }

    &[data-type="tipo2"] {
      .read-more {
        &--info {
          &:before {
            display: inline-block;
          }
        }
      }
    }
  }
}
