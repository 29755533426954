/* --- WEBDOOR --- */
@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.webdoor {
  &--controls {
    background-color: transparent;
    float: left;
    pointer-events: none;
    padding: 30px;
    text-align: center;
    width: 100%;
    vertical-align: top;

    .btn {
      margin: 0 14px;
      padding: 0;
      pointer-events: auto;

      .icon {
        @include rem('font-size', 14px);
        color: $white-900;
      }

      &:disabled {
        opacity: 0.7;
      }
    }
  }
}
