/* --- ALERT BUTTON --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/tools/mixins';

@import '../../styles/components/animations';

.alert-button {
  bottom: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  width: 100%;
  z-index: 9999999;

  &--content,
  &--top {
    float: left;
    position: relative;
    width: 100%;
  }

  &--content {
    background-color: $white-900;
    border-radius: 8px 8px 0px 0px;
    bottom: 0;
    height: 0;
    left: 0;
    padding: 0;
    position: absolute;
    pointer-events: auto;
    text-align: center;
    transition: height 400ms $base-ease;
    transform: translateY(100%);
    z-index: 2;

    .description,
    .message,
    .title {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 14px, 500, $purple-100);
      margin: 0 0 20px;
      line-height: 1.6em;

      a {
        color: $purple-900;
        margin: 0 0 0 5px;
        text-decoration: none;
      }
    }

    .message {
      @include font($font-default, 18px, 600, $black-700);
      margin: 0 0 25px;
    }

    .title {
      @include font($font-default, 22px, 600, $purple-900);
      margin: 0 0 10px;

      .icon {
        @include rem('font-size', 30px);
        color: $purple-900;
        right: calc(100% + 10px);
        position: absolute;
        top: calc(50% - 2px);
        transform: translate(0, -50%);
      }
    }

    .wrapper {
      display: inline-block;
      padding: 40px 110px;
      text-align: left;
    }
  }

  &--top {
    padding: 0 15px 40px;
    text-align: right;

    .btn {
      box-shadow: 0px 14px 26px rgba(0, 0, 0, 0.16);
      background-color: $white-900;
      padding-left: 35px;
      padding-right: 20px;
      pointer-events: auto;

      .icon {
        @include rem('font-size', 13px);
        left: 15px;
        position: absolute;
        top: calc(50% - 2px);
        transform: translate(0, -50%);
      }
    }
  }

  &:before {
    background-color: rgba($black-900, 0.6);
    bottom: 0;
    content: '';
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: all 300ms $base-ease;
    width: 100vw;
    z-index: 1;
  }

  &[data-active="true"] {
    .alert-button {
      &--content {
        height: auto;
      }

      &--top {
        display: none;
      }
    }

    &:before {
      display: inline-block;
      opacity: 1;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {
  .alert-button {
    &--top {
      padding: 0 15px 25px;

      .btn {
        padding-left: 20px;
        padding-right: 5px;

        .icon {
          left: 5px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 640px) {
  .alert-button {
    &--content {
      .description {
        @include rem('font-size', 13px);
      }

      .message {
        @include rem('font-size', 14px);
      }

      .title {
        @include rem('font-size', 16px);
        padding: 0 0 0 20px;

        .icon {
          @include rem('font-size', 21px);
          left: -2px;
        }
      }

      .wrapper {
        padding: 20px 20px 30px;
      }
    }

    &--top {
      height: 82px;

      .btn {
        padding: 7px 10px 7px 20px;
      }

      .wrapper {
        padding: 0;
      }
    }
  }
}
