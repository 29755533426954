/* --- WEBDOOR --- */
@import '../../styles/components/animations';
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.webdoor {
  float: left;
  height: calc(100vh - 94px);
  overflow: hidden;
  position: relative;
  width: 100%;
  user-select: none;
  vertical-align: top;
  will-change: transform;

  &--next {
    background-color: $red-900;
    border: none;
    border-radius: 100%;
    bottom: 20px;
    height: 40px;
    left: 50%;
    outline: none;
    padding: 0;
    pointer-events: auto;
    position: absolute;
    transform: translate(-50%, 0);
    width: 40px;
    z-index: 3;

    .icon {
      @include rem('font-size', 9px);
      color: $white-900;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: calc(50% + 2px);
    }
  }

  > .slider {
    height: 100%;
    width: 100%;
    z-index: 2;

    .slider {
      &--item {
        display: none;
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 0;

        &[data-last="true"] {
          display: inline-block;
          left: 0;
          position: absolute;
          top: 0;
          z-index: 1;
        }

        &[data-current="true"] {
          z-index: 2;
        }
      }

      &--list {
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  &[data-direction="prev"],
  &[data-direction="next"],
  &[data-direction="none"] {
    > .slider {
      .slider {
        &--item {
          &[data-current="true"] {
            animation: slide-in-fwd-center 600ms $base-ease both;
            display: inline-block;
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor {
    height: calc(100vh - 60px);
  }
}
