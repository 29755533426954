/* --- CATEGORIES TAB CONTENT --- */
@import '../../../styles/settings/colors';
@import '../../../styles/components/animations';

.categories-tab-content {
  background-color: $white-700;
  border-radius: 8px;
  overflow: hidden;
  margin: 37px 0 0;
  padding: 30px 30px 60px;
  position: relative;
  max-width: 665px;
  width: 100%;
  vertical-align: top;

  &--item {
    display: none;
    float: left;
    opacity: 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    &[data-current="true"] {
      animation: slide-in-bottom 0.5s $base-ease both;
      display: inline-block;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {
  .categories-tab-content {
    max-width: none;
    width: 60%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .categories-tab-content {
    margin: 30px 0 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .categories-tab-content {
    display: none;
  }
}