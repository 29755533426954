/* --- TEXT HIGHLIGHT --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';


.text-highlight {
  float: left;
  margin: 5px 0 0;
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: top;

  &--content {
    @include font($font-default, 26px, 600, $purple-900);
    float: none;
    line-height: 1.4em;
    letter-spacing: -0.01em;
    max-width: 720px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    p {
      float: left;
      line-height: 1.4em;
      margin: 10px 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    b,
    strong {
      color: $red-900;
      line-height: 1.4em;
      vertical-align: baseline;
    }
  }

  .wrapper {
    display: inline-block;
    float: none;
  }

  & + .title-text {
    margin-top: 40px;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .text-highlight {
    &--content {
      @include rem('font-size', 18px);
    }
  }
}