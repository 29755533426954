/* --- PRODUCT CREDITS FISIC ITEM CONTENT --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/components/animations';

.product-credits-fisic-item {
  &--content {
    display: none;
    position: relative;
    width: 100%;
    vertical-align: top;

    .product-credits-fisic-item-content-gallery {
      display: none;
      opacity: 0;
    }

    &[data-active="true"] {
      display: inline-block;
      height: auto;

      &[data-loading="true"] {
        .product-credits-fisic-item-content-gallery {
          animation: slide-in-bck-center 0.7s $base-ease both;
          display: inline-block;
        }

        &:after {
          display: none;
        }

        &:before {
          animation: slide-in-bck-center 0.7s $base-ease both;
          background-color: $purple-900;
          content: '';
          height: 40px;
          left: 0;
          opacity: 0;
          position: absolute;
          top: -40px !important;
          width: 100vw;
          z-index: -1;
        }
      }
    }

    &:before,
    &:after {
      animation: loading2 3.5s ease both infinite;
      background-color: $purple-900;
      border-radius: inherit;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      top: 10px;
      transform: translateX(-50%);
      width: 100%;
    }

    &:after {
      background-color: $purple-700;
      animation: loading 3.5s ease both infinite;
    }
  }
}

/* --- QUERIES --- */
/* Extra Small Devices, Phones */
@media only screen and (max-width : 640px) {
  .product-credits-fisic-item {
    &--content {
      &[data-active="true"] {
        &[data-loading="true"] {
          &:before {
            height: 7px;
            top: -7px !important;
          }
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    width: 20%;
  }
  40% {
    width: 100%;
  }
  80% {
    width: 20%;
  }
  90% {
    width: 100%;
  }
  100% {
    width: 20%;
  }
}

@keyframes loading2 {
  0% {
   width: 20%;
  }
  40% {
    width: 80%;
  }
  80% {
    width: 100%;
  }
  90% {
    width: 80%;
  }
  100% {
    width: 20%;
  }
}
