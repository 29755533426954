/* --- PRODUCT CREDITS FISIC CONTENT --- */
@import '../../../styles/settings/colors';
@import '../../../styles/tools/mixins';

.product-credits-fisic-content {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--item,
  &--list {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &--item {
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px 0;
    text-align: center;

    &:nth-child(1) {
      .product-credits-fisic-item {
        &--title {
          .name {
            color: $purple-900;
          }
        }
      }
    }
  
    &:nth-child(2) {
      .product-credits-fisic-item {
        &--title {
          .name {
            span {
              &:first-child {
                color: $red-900;
              }
            }
          }
        }
      }
    }

    &:nth-child(3) {
      .product-credits-fisic-item {
        &--title {
          .name {
            span {
              &:first-child {
                color: $purple-500;
              }
            }
          }
        }
      }
    }
  }
}