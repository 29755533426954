/* --- PROFILE --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/settings/grid';

@import '../../styles/tools/mixins';

.profile {
  float: left;
  margin: 40px 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--image,
  &--item {
    text-align: left;
  }

  &--image {
    border-radius: 0 0 80px 0;
    max-height: 50%;
    right: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 50vw;
    z-index: 3;

    picture,
    img {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    picture {
      height: 100%;

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  &--item {
    float: left;
    position: relative;
    width: 50%;
    vertical-align: top;
    z-index: 2;

    .description,
    .title {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 16px, 500, $purple-100);
      line-height: 1.7em;
    }
    
    .title {
      @include font($font-default, 26px, 600, $purple-900);
      letter-spacing: -0.01em;
      line-height: 1em;
      margin: 0 0 20px;
    }

    &[data-type="1"] {
      background-color: $gray-100;
      border-radius: 80px 0 0 0;
      padding: 60px 65px;
      width: 50%;
    }

    &[data-type="2"] {
      margin: 90px 0 0;
      padding: 60px 95px;

      &:before {
        background-color: $gray-100;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(0, 0);
        width: 50vw;
      }
    }

    &[data-type="3"] {
      background-color: $gray-100;
      padding: 60px 0 60px 50%;
      width: 100%;

      .description,
      .title {
        padding: 0 95px;
      }

      &:before {
        background-color: $gray-100;
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        vertical-align: top;
      }
    }

    &[data-type="4"] {
      float: none;
      max-width: 80%;
      padding: 60px 120px;
      width: 100%;

      .title {
        color: $black-700;
      }

      &:after,
      &:before {
        background-color: $red-900;
        content: '';
        position: absolute;
      }

      &:after {
        height: 100%;
        right: 0;
        top: 0;
        width: 2px;
      }

      &:before {
        bottom: 0;
        height: 2px;
        right: 0;
        width: 100vw;
      }
    }
  }

  .wrapper {
    display: inline-block;
    padding: 0;
    position: relative;
    text-align: center;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .profile {
    &--image {
      height: calc(50% - 40px);
    }

    &--item {
      .description {
        @include rem('font-size', 14px);
      }

      .title {
        @include rem('font-size', 18px);
        margin: 0 0 10px;
      }

      &[data-type="1"] {
        padding: 50px 30px 50px 60px;
      }

      &[data-type="2"] {
        margin-top: 105px;
        padding: 50px 60px 50px 30px;
      }

      &[data-type="3"] {
        padding: 50px 30px 50px 50%;

        .description,
        .title {
          padding: 0 30px;
        }
      }

      &[data-type="4"] {
        padding: 50px 30px 50px 0;
      }
    }

    .wrapper {
      padding: 0 40px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .profile {
    margin: 0 0 40px;
    
    &--image {
      background-color: $gray-100;
      border-radius: 0;
      height: auto;
      left: initial;
      right: initial;
      overflow: initial;
      max-height: initial;
      position: relative;
      top: initial;
      transform: none;
      width: 100%;

      picture {
        overflow: hidden;
        border-radius: 0 0 80px;
      }
    }

    &--item {
      &[data-type="1"],
      &[data-type="2"],
      &[data-type="3"],
      &[data-type="4"] {
        border-radius: 0;
        margin: 0;
        padding: 30px 20px;
        width: 100%;
      }

      &[data-type="3"] {
        .description,
        .title {
          padding: 0;
        }
      }

      &[data-type="4"] {
        max-width: none;

        &:after {
          display: none;
        }
      }
    }

    .wrapper {
      padding: 0;
    }
  }
}
