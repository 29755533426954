/* --- READ MORE --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/grid';

@import '../../../styles/tools/mixins';
@import '../../../styles/components/animations';
@import '../../../styles/components/forms/buttons';
@import '../../../styles/components/typography';

.read-more {
  &--info {
    background-color: $red-900;
    align-items: flex-start;
    display: inline-flex;
    justify-content: flex-end;
    flex-flow: column wrap;
    float: left;
    position: relative;
    padding: 15px 0 0 0;
    transition: all 400ms $base-ease;
    width: calc(50% - 75px);
    vertical-align: top;
    z-index: 2;

    &--content {
      background-color: $red-900;
      float: left;
      overflow: initial;
      padding: 20px 75px 50px 15px;
      position: relative;
      width: 100%;

      .btn,
      .description {
        position: relative;
        z-index: 2;
      }

      .btn {
        &-border {
          border-color: $white-900;
          color: $white-900;
        }
      }

      .description {
        @extend %text;
        color: $white-900;
        margin: 0 0 40px;
      }

      &:after,
      &:before {
        background-color: inherit;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
      }

      &:after {
        left: 100%;
        width: 75px;
      }

      &:before {
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        z-index: 1;
      }
    }

    .title {
      @extend %title-1;
      color: $white-900;
      float: left;
      margin: 0;
      padding: 0 75px 0 15px;
      width: 100%;

      &:before {
        background-color: $white-900;
        left: 15px;
        top: -15px;
      }
    }

    &:after,
    &:before {
      background-color: inherit;
      content: '';
      position: absolute;
    }

    &:after {
      height: calc(100% + 35px);
      left: -15px;
      top: -35px;
      width: 15px;
    }

    &:before {
      height: 35px;
      left: 0;
      top: -35px;
      width: 100%;
    }
  }

  &[data-type="tipo2"] {
    .read-more {
      &--info {
        background-color: $green-900;

        &--content {
          background-color: $green-900;
        }

        &:before {
          display: none;
        }

        &:after {
          height: 100%;
          top: 0;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .read-more {
    &--info {
      left: 15px;
      margin: 0;
      padding: 55px 40px 0 30px;
      width: calc(50% + 15px);

      &--content {
        padding: 0 0 40px;

        &:before {
          width: 70px;
        }

        &:after {
          left: calc(100% + 40px);
          width: 50px;
        }
      }

      .title {
        margin-bottom: 10px;
        padding: 0;

        &:before {
          left: 0;
        }
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 640px) {
  .read-more {
    &--info {
      left: 0;
      margin: 0;
      order: 2;
      padding: 50px 20px 0;
      width: 100%;

      &:before {
        display: inline-block;
        left: 20px;
        top: -25px;
        width: calc(100% - 40px);
      }
    }

    &[data-type="tipo2"] {
      .read-more {
        &--info {
          &:before {
            display: inline-block;
          }
        }
      }
    }
  }
}
