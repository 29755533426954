/* --- SELECT --- */
@import '../../styles/settings/colors';
@import '../../styles/tools/mixins';

.select {
  float: left;
  min-width: 230px;
  position: relative;
  vertical-align: top;
  width: 100%;
  z-index: 9999;

  &--list {
    background-color: $white-900;
    box-shadow: -1px 2px 10px rgba($black-900, 0.1);
    border-radius: 0 0 4px 4px;
    display: none;
    left: 0;
    max-height: 300px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    vertical-align: top;
    z-index: 1;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &:before {
      background-color: $white-900;
      border-radius: 0;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
    }
  }

  &[data-type="2"] {
    .select {
      &--list {
        background-color: $purple-650;

        &--item {
          .select {
            &-item {
              border-color: $purple-900;

              .text {
                color: $white-900;
              }
      
              &[data-active="true"] {
                .text {
                  color: $red-900;
                }
              }
            }
          }

          &:first-child {
            .select {
              &-item {
                border-top: 1px solid $purple-900;
              }
            }
          }
        }

        &:before {
          display: none;
        }
      }

      &-title {
        background-color: $purple-650;

        &--content {
          .text {
            color: $white-900;
          }
        }

        &[data-active="true"] {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }

  &[data-active="true"] {
    z-index: 99999;

    .select {
      &--list {
        display: inline-block;
      }

      &-title {
        border-radius: 4px 4px 0 0;
      }
    }
  }
}