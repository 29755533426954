/* --- BOX ALERT --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.box-alert {
  float: left;
  margin: 15px 0;
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: top;

  &--content {
    background-color: $white-700;
    max-width: 790px;
    padding: 30px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .description,
    .title {
      float: left;
      line-height: 1.7em;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 14px, 500, $purple-100);
      line-height: 1.7em;
    }

    .title {
      @include font($font-default, 18px, 600, $black-700);
      margin: 0 0 5px;
    }

    &:before {
      background-color: $white-100;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
      vertical-align: top;
    }
  }

  & + .title-text {
    margin-top: 0;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .box-alert {
    &--content {
      max-width: 570px;

      .description {
        @include rem('font-size', 13px);
      }

      .title {
        @include rem('font-size', 14px);
      }
    }
  }
}