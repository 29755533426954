/* --- IMAGE TEXT --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

@import '../../styles/components/typography';

.image-text {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--body, 
  &--image,
  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    &:empty {
      display: none;
    }
  }

  &--body {
    @include font($font-default, 16px, 500, $purple-100);
    line-height: 1.7em;
    padding: 0 20px;
    text-align: left;
  }

  &--title {
    @extend %title-1;
    color: $purple-900;
    margin: 0 0 20px;
    text-align: left;

    &:before {
      background-color: $red-900;
    }
  }

  &[data-type="center"] {
    margin: 20px 0 60px;
    text-align: center;
  }

  &[data-type="right"] {
    margin: 60px 0;
    padding: 0 20px;

    .image-text {
      &--image {
        width: 40%;

        .image {
          float: left;
          height: auto;
          position: relative;
          width: 100%;
          
          img {
            float: left;
            height: auto;
            width: 100%;
            vertical-align: top;
          }
        }
      }

      &--body {
        width: 60%;
      }
    }

    .wrapper {
      padding: 0 95px 0 75px;
    }
  }

  &[data-type="left"] {
    margin: 0 0 40px;

    .image-text {
      &--body,
      &--title {
        float: right;
        margin: 0 0 0 65px;
        width: calc(100% - (410px + 65px));
      }

      &--body {
        padding: 0;
      }

      &--image {
        background-color: $red-900;
        left: 0;
        position: absolute;
        top: 0;
        width: auto;

        .image {
          border-radius: 0 0 0 190px;
          overflow: hidden;
        }
      }
    }

    .wrapper {
      align-content: center;
      align-items: center;
      display: inline-flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      min-height: 575px;
      padding: 0;
      max-width: 980px;
      text-align: right;
    }
  }

  .wrapper {
    display: inline-block;
    float: none;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .image-text {
    &[data-type="left"] {
      margin: 20px 0;

      .image-text {
        &--body,
        &--title {
          margin: 0 0 0 60px;
          padding: 0 20px 0 0;
          width: calc(100% - (340px + 60px));
        }

        &--image {
          height: auto;
          left: 70px;
          overflow: hidden;
          top: 50%;
          transform: translate(0, -50%);
          width: 270px;

          .image {
            border-radius: 0 0 0 120px;
            img {
              object-fit: cover;
              width: 100%;
            }
          }
        }

        &--title {
          margin-top: 50px;
        }
      }

      .wrapper {
        min-height: initial;
        padding: 0 25px;
      }
    }

    &[data-type="right"] {
      .image-text {
        &--body,
        &--image {
          padding: 0 15px;
        }

        &--body {
          width: 55%;
        }

        &--image {
          width: 45%;
        }
      }

      .wrapper {
        padding: 0;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .image-text {
    &[data-type="left"] {
      align-items: flex-start;
      display: inline-flex;
      justify-content: center;
      flex-flow: row wrap;

      .image-text {
        &--body,
        &--title {
          display: inline-flex;
          margin: 0;
          width: 100%;
        }

        &--body {
          order: 3;
        }

        &--image {
          left: initial;
          margin: 0 0 55px;
          padding: 0;
          position: relative;
          top: initial;
          order: 1;
          transform: none;
          width: 100%;

          .image {
            width: 100%;

            img {
              object-fit: initial;
              height: auto;
              width: 100%;
            }
          }
        }

        &--title {
          order: 2;
        }
      }
    }

    &[data-type="right"] {
      .image-text {
        &--body,
        &--image {
          display: inline-flex;
          width: 100%;
        }

        &--body {
          order: 2;
          padding: 0;
        }

        &--image {
          order: 1;
          margin: 0 0 20px;
        }
      }

      .wrapper {
        align-items: center;
        display: inline-flex;
        flex-flow: row wrap;
        justify-content: center;
      }
    }

    &[data-type="center"] {
      .image-text {
        &--image {
          width: 100%;

          .image {
            width: auto;

            img {
              height: auto;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
