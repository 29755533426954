/* --- PRODUCT CREDITS FISIC --- */
@import '../../styles/settings/settings';
@import '../../styles/tools/mixins';

@import '../../styles/components/typography';

.product-credits-fisic {
  float: left;
  margin: 0 0 210px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--content {
    @extend %text;
    color: $purple-100;
    float: none;
    font-weight: 500;
    margin: 50px 0 30px;
    max-width: 730px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .wrapper {
    text-align: center;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-fisic {
    &--content {
      max-width: 570px;
      margin: 30px 0 5px;
    }
  }
}