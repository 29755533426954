/* --- HEADER MENU --- */
@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header--menu {
  @include box-display;
  float: left;
  height: 94px;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: 100%;
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .header {
    &--menu {
      background-color: $white-900;
      box-shadow: 14px 0px 26px rgba(0, 0, 0, 0.16);
      left: -100%;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
      padding: 60px 0 0;
      position: absolute;
      top: 0px;
      transition: all 400ms $base-ease;
      width: 290px;
      z-index: 2;
    }
  }
}
