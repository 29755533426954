/* --- PRODUCT CREDITS JURIDIC ITEM --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/typography';

.product-credits-juridic-item {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  .description,
  .title {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .description {
    @extend %text;
    color: $purple-100;
    font-weight: 500;
  }

  .title {
    .name,
    .subtitle {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .name {
      @include font($font-default, 26px, 600, $purple-900);
      letter-spacing: -0.01em;
    }

    .subtitle {
      @include font($font-default, 12px, 600, $gray-400);
      margin: 0 0 5px;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-juridic-item {
    .description {
      @include rem('font-size', 13px);
    }

    .title {
      .name {
        @include rem('font-size', 18px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .product-credits-juridic-item {
    .title {
      .name {
        @include rem('font-size', 16px);
      }
    }
  }
}
