/* --- REPORTS --- */
@import '../../styles/settings/settings';
@import '../../styles/components/typography';
@import '../../styles/tools/mixins';

.reports {
  float: left;
  margin: 50px 0 25px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--container {
    max-width: 730px;
    position: relative;
    text-align: left;
    width: 100%;
  }

  .title-main {
    @extend %title-1;
    color: $purple-900;
    width: 100%;

    &:before {
      background-color: $red-900;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .reports {
    &--container {
      max-width: 570px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .reports {
    .wrapper {
      padding: 0 20px;
    }
  }
}
