/* --- TITLE TEXT --- */
@import '../../styles/settings/settings';
@import '../../styles/components/typography';
@import '../../styles/tools/mixins';

.title-text {
  float: left;
  margin: 75px 0 25px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--content,
  &--text {
    float: left;
    position: relative;
    width: 100%;
  }

  &--content {
    float: none;
    max-width: 730px;
    text-align: left;

    .title-main {
      @extend %title-1;
      color: $purple-900;
      margin: 0;

      &:before {
        background-color: $red-900;
      }
    }

    & * {
      margin-top: 0;
    }
  }

  &--text {
    @include font($font-default, 16px, 500, $purple-100);
    float: left;
    margin: 20px 0 0;
    line-height: 1.9em;
    position: relative;
    width: 100%;

    h3,
    h4,
    h5,
    h6 {
      @include font($font-default, 26px, 600, $black-700);
      letter-spacing: -0.01em;
      line-height: 1.4em;
      margin: 10px 0 5px;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:before {
        border: none;
        content: '';
        border-bottom: 8px solid transparent;
        border-left: 10px solid $green-900;
        border-top: 8px solid transparent;
        height: 0;
        outline: none;
        right: calc(100% + 20px);
        padding: 0;
        position: absolute;
        top: 10px;
        user-select: none;
        width: 0;
      }
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:empty {
      display: none;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .title-text {
    &--content {
      max-width: 570px;
    }

    &--text {
      h3,
      h4,
      h5,
      h6 {
        @include rem('font-size', 18px);

        &:before {
          border-bottom: 6px solid transparent;
          border-left: 8px solid $green-900;
          border-top: 6px solid transparent;
          left: -17px;
          top: 6px;
        }
      }
    }
  }
}