/* --- FOOTER TOP --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.footer--top {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--partners,
  &--title {
    float: left;
    padding: 0 10px;
    position: relative;
    vertical-align: top;
  }

  &--partners {
    padding: 20px 0 0;
    text-align: right;
    width: 40%;

    &--item {
      float: left;
      margin: 0 0 40px;
      position: relative;
      width: 100%;
      vertical-align: top;

      .image,
      .title {
        display: block;
        float: none;
        position: relative;
      }

      .title {
        @include font($font-default, 11px, 600, $purple-900);
        text-transform: uppercase;
      }

      .image {
        float: right;
      }
    }
  }

  &--title {
    text-align: left;
    width: 100%;

    .icon {
      float: left;
      position: relative;
      vertical-align: top;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .footer--top {
    padding: 0 25px;

    &--partners {
      padding: 0 10px 30px;
      top: -40px;
      width: 50%;

      &--item {
        .image {
          width: 230px;
        }
      }
    }

    &--title {
      img {
        width: 85px;
      }

      .footer--credits {
        &.mobile {
          margin: 10px 0 0;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer--top {
    padding: 0 15px;

    &--partners {
      padding: 0 10px;
      width: 45%;

      &--item {
        margin: 0 0 20px;

        .image {
          height: 20px;
          object-fit: contain;
          width: auto;
        }

        .title {
          @include rem('font-size', 10px);
          margin: 0 0 10px;
        }
      }
    }

    &--title {
      padding: 0 0 30px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .footer--top {
    padding: 0 10px;
    
    &--partners {
      padding: 15px 10px 0px;
      top: 0;
      width: 100%;

      &--item {
        text-align: left;

        .image {
          float: left;
        }
      }
    }

    &--title {
      padding: 0 0 15px;
    }

    .wrapper {
      padding: 0;
    }
  }
}