/* --- FOOTER NETWORKS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.footer {
  &--networks {
    display: inline-flex;
    float: left;
    flex-flow: row nowrap;
    margin: 0 0 20px;
    list-style: none;
    padding: 0;
    position: relative;
    width: auto;
    vertical-align: top;

    &--item {
      float: left;
      line-height: 1em;
      margin: 0 20px 0 0;
      position: relative;

      .link {
        color: $gray-900;
        float: left;
        position: relative;
        top: -4px;
        vertical-align: top;

        .icon {
          position: relative;
          vertical-align: top;
        }

        &:after {
          background-color: $purple-900;
          content: '';
          height: 100%;
          left: 0;
          mix-blend-mode: color-burn;
          opacity: 0;
          position: absolute;
          transition: all 400ms $base-ease;
          top: 0;
          width: 100%;
          vertical-align: top;
          z-index: 2;
        }
      }

      &:hover {
        .link {
          &:after {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {
  .footer {
    &--networks {
      margin: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .footer {
    &--networks {
      &--item {
        .link {
          .icon {
            @include rem('font-size', 22px);
            top: 0px;
          }
        }
      }
    }
  }
}
