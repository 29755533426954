/* --- PHOTO SPECIAL --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/components/typography';

@import '../../styles/tools/mixins';

.photo-special {
  background-color: $white-900;
  border: none;
  float: left;
  overflow: hidden;
  padding: 0 0 70px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--block,
  &--logo {
    float: left;
    position: relative;
    width: 50%;
    vertical-align: top;
  }

  &--block {
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;

    .block {
      border: 2px solid $purple-10;
      border-bottom: none;
      border-right: none;
      bottom: 0;
      height: 205px;
      right: 0;
      position: absolute;
      width: 210px;
      
      &:before {
        border: 2px solid $purple-10;
        border-right: none;
        border-bottom: none;
        bottom: 62px;
        content: '';
        height: 76px;
        right: 69px;
        position: absolute;
        width: 76px;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      border: 2px solid $red-900;
      border-left: none;
      border-top: none;
      border-radius: 0 0 85px 0;
      bottom: -20px;
      height: 1000px;
      right: 69px;
      width: 1000px;
      z-index: 2;
    }

    &:after {
      border: none;
      border-bottom: 2px solid $purple-10;
      bottom: 62px;
      height: 0px;
      right: 71px;
      width: 100%;
    }
  }

  &--logo {
    z-index: 1;

    img {
      float: left;
      height: auto;
      width: 34px;
    }
  }

  .wrapper {
    display: inline-block;
    position: relative;
    vertical-align: top;
    z-index: 2;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .photo-special {
    padding-bottom: 0;

    &--block {
      .block {
        height: 103px;
        width: 105px;

        &:before {
          bottom: 28px;
          height: 39px;
          right: 32px;
          width: 40px;
        }
      }

      &:before {
        border-radius: 0 0 55px;
        right: 32px;
      }

      &:after {
        bottom: 28px;
        right: 35px;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .photo-special {
    &--logo {
      img {
        width: 23px;
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 640px) {
  .photo-special {
    padding: 0 0 15px;

    &--block {
      .block {
        border-width: 1px;
        height: 45px;
        width: 42px;

        &:before {
          border-width: 1px;
          bottom: 14px;
          height: 16px;
          right: 12px;
          width: 16px;
        }
      }

      &:before {
        border-width: 1px;
        border-radius: 0 0 40px;
        right: 12px;
      }

      &:after {
        border-width: 1px;
        bottom: 14px;
        right: 12px;
      }
    }

    .wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      padding: 0;
    }
  }
}
