/* --- CATEGORIES TAB CONTENT ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.categories-tab-content-item {
  float: left;
  margin: 0 0 30px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--name,
  &--list {
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--name {
    @include font($font-default, 14px, 600, $purple-900);
    line-height: 1.4em;
    margin: 0 0 15px;
    text-transform: uppercase;
  }

  &--list {
    list-style: none;

    &--item {
      float: left;
      margin: 4px 0;
      position: relative;
      width: 100%;
      vertical-align: top;

      .btn {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: 100%;

        &-tab {
          @include font($font-default, 15px, 500, $purple-100);
          background-color: $white-700;
          border: 2px solid transparent;
          border-radius: 8px;
          line-height: 1em;
          margin: 0;
          padding: 25px 15px;
          transition: all 400ms $base-ease;
          text-align: left;
          width: 100%;

          &:hover {
            border-color: $purple-900;
            color: $purple-900;
          }

          &[data-active="true"] {
            background-color: $purple-900;
            color: $white-900;
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .categories-tab-content-item {
    &--list {
      &--item {
        .btn {
          &-tab {
            @include rem('font-size', 14px);
            border-radius: 4px;
            padding: 11px 15px;
          }
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .categories-tab-content-item {
    &--list {
      &--item {
        .btn {
          &-tab {
            @include rem('font-size', 13px);
          }
        }
      }
    }

    &--name {
      @include rem('font-size', 12px);
      margin: 0 0 10px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .categories-tab-content-item {
    &--list {
      display: none;
    }

    &--name {
      padding: 0 20px;
    }
  }
}