/* --- LIST CARROUSEL PHOTO --- */

.list-carrousel-photo {
  float: left;
  margin: 50px 0 10px;
  position: relative;
  width: 100%;
  vertical-align: top;

  .wrapper {
    text-align: center;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-photo {
    margin: 40px 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-carrousel-photo {
    margin: 30px 0;

    .wrapper {
      padding: 0;
    }
  }
}