/* --- SELECT TITLE --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.select-title {
  background-color: $white-900;
  border-radius: 4px;
  height: 40px;
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--content {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0 20px 0 8px;
    position: relative;
    width: 100%;
    user-select: none;
    vertical-align: top;

    .icon,
    .text {
      float: left;
      position: relative;
    }

    .icon {
      @include rem('font-size', 5px);
      color: $red-900;
      right: 15px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    .text {
      @include font($font-default, 13px, 500, $gray-400);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &[data-type="true"] {
        color: $purple-100;
      }
    }
  }
}