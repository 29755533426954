/* --- LIST CONTENT ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.list-content-item {
  align-content: space-between;
  align-items: flex-start;
  background-color: $white-700;
  border-radius: 8px;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  float: left;
  min-height: 355px;
  height: 100%;
  padding: 30px 25px;
  transition: all 400ms $base-ease;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--icon,
  &--description,
  &--info,
  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--description {
    @include font($font-default, 14px, 500, $purple-100);
    margin: 0 0 40px;
    line-height: 1.7em;
  }

  &--icon {
    margin: 0 0 10px;
    
    .icone {
      float: left;
      position: relative;
      vertical-align: top;

      img {
        float: left;
        height: auto;
        width: auto;
      }
    }
  }

  &--title {
    .subtitle,
    .title {
      float: left;
      margin: 0;
      line-height: 1.4em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .subtitle {
      @include font($font-default, 12px, 400, $purple-900);
      margin: 0 0 5px;
      text-transform: uppercase;
    }

    .title {
      @include font($font-default, 20px, 600, $purple-900);
      margin: 0 0 10px;
      line-height: 1em;
    }
  }

  .btn-border {
    border-color: $purple-50;
    color: $purple-900;
    text-decoration: none;
  }

  &:hover {
    background-color: $white-800;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-content-item {
    padding: 15px;

    &--description {
      @include rem('font-size', 12px);
    }

    &--title {
      .subtitle {
        @include rem('font-size', 10px);
      }
      
      .title {
        @include rem('font-size', 15px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-content-item {
    min-height: initial;

    &--description {
      margin: 0 0 20px;
    }
  }
}