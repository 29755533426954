/* --- PRODUCT CREDITS FISIC ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

.product-credits-fisic-item {
  &--title {
    float: left;
    padding: 30px 35px;
    max-width: 295px;
    position: relative;
    width: 100%;

    .icone,
    .name {
      float: left;
      line-height: 1em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .icone {
      img {
        vertical-align: top;
      }
    }

    .name {
      @include font($font-default, 26px, 300, $black-750);
      margin: 15px 0 0;
      line-height: 1.1em;

      span {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-fisic-item {
    &--title {
      padding: 30px;
      max-width: 245px;

      .name {
        @include rem('font-size', 20px);
      }
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 640px) {
  .product-credits-fisic-item {
    &--title {
      padding: 20px 15px;
      max-width: none;
      width: 100%;

      .icone {
        width: 20px;

        img {
          height: auto;
          width: 100%;
        }
      }

      .name {
        margin: 0;
        padding-left: 15px;
        width: calc(100% - 40px);
      }
    }
  }
}