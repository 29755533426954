/* --- TAB SUBMENU CONTENT --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

.tab-submenu {
  &--content {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top; 
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tab-submenu {
    &--content {
      overflow: hidden;
      overflow-x: auto;
    }
  }
}