
/* --- PRODUCT CREDITS FISIC ITEM SELECT --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

.product-credits-fisic-item {
  &--select {
    float: left;
    padding: 30px 20px;
    position: relative;
    width: calc(100% - 295px);
    vertical-align: top;

    &--description,
    &--form {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &--description {
      p {
        @include font($font-default, 12px, 400, $purple-100);
        line-height: 1.7em;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &--form {
      .description,
      .title {
        float: left;
        margin: 0;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .description {
        @include font($font-default, 12px, 400, $purple-100);
        line-height: 1.7em;
        margin: 0 0 20px;
      }

      .title {
        @include font($font-default, 14px, 600, $black-900);
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-fisic-item {
    &--select {
      width: calc(100% - 245px);
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 640px) {
  .product-credits-fisic-item {
    &--select {
      padding: 0 15px 25px;
      width: 100%;

      &--description {
        margin: 0 0 10px;
      }

      &--form {
        .select {
          width: 100%;
        }
      }
    }
  }
}