/* --- CATEGORIES TAB --- */
@import '../../styles/settings/colors';

.categories-tab {
  float: left;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;

  .wrapper {
    text-align: left;
  }
}
