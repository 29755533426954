/* --- LIST CONTENT --- */

.list-content {
  float: left;
  padding: 50px 0;
  position: relative;
  width: 100%;
  vertical-align: top;
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-content {
    padding: 30px 0;

    .wrapper {
      padding: 0 35px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-content {
    padding: 25px 0;

    .wrapper {
      padding: 0 20px;
    }
  }
}