/* --- REPORTS SUBITEM --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.reports {
  &--subitem {
    align-items: center;
    border: 1px solid $purple-50;
    border-radius: 8px;
    display: inline-flex;
    justify-content: space-between;
    flex-flow: row wrap;
    height: 80px;
    margin: 7px 0;
    padding: 0 20px;
    position: relative;
    transition: all 400ms $base-ease;
    width: 100%;
    vertical-align: top;

    &--info {
      float: left;
      position: relative;
      width: 70%;

      .icon {
        @include rem('font-size', 25px);
        color: $red-900;
        left: 5px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .document {
        float: left;
        padding: 0 0 0 40px;
        position: relative;
        width: 100%;
  
        .name,
        .extension {
          float: left;
          line-height: 1em;
          position: relative;
          width: 100%;
          vertical-align: top;
        }

        .name {
          @include font($font-default, 18px, 600, $purple-900);
        }

        .extension {
          @include font($font-default, 12px, 500, $purple-100);
          margin: 5px 0 0;
        }
      }
    }

    .btn {
      float: right;
      padding: 8px 20px;
    }

    &:hover {
      border-color: $purple-900;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .reports {
    &--subitem {
      &--info {
        .document {
          .extension {
            @include rem('font-size', 10px);
          }

          .name {
            @include rem('font-size', 14px);
            line-height: 1.5em;
          }
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .reports {
    &--subitem {
      padding: 0 15px;

      &--info {
        .document {
          margin: 0;

          .extension {
            margin: 0;
          }
        }

        .icon {
          top: 0;
          transform: translate(0, 0);
        }
      }
    }
  }
}