/* --- LIST CARROUSEL VIDEO ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-carrousel-video-item {
  float: left;
  padding: 5px;
  position: relative;
  width: 100%;
  vertical-align: top;
  will-change: transform;

  &--content,
  &--image {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--content {
    padding: 20px;
    text-align: left;

    .description,
    .duration,
    .title {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @include font($font-default, 14px, 500, $purple-100);
    }

    .duration {
      @include font($font-default, 13px, 500, $purple-100);
      margin: 20px 0 10px;
    }

    .title {
      @include font($font-default, 18px, 600, $purple-900);
      line-height: 1.4em;
      margin: 0 0 5px;
    }
  }

  &--image {
    border-radius: 6px;
    margin: 5px 5px 0;
    overflow: hidden;
    width: calc(100% - 20px);

    picture,
    img {
      background-color: transparent;
      float: left;
      position: relative;
    }
  }
  
  &:before {
    background-color: $white-700;
    border-radius: 8px;
    content: '';
    height: calc(100% - 10px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 10px);
  }
}