/* --- LIST CONTENT LIST --- */

.list-content-list {
  display: grid;
  float: left;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 30px 30px;
  margin: 0;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--item {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &[data-type="tipo2"] {
    grid-template-columns: 1fr 1fr;
    float: none;
    max-width: 730px;
    margin: 0 auto;
    text-align: left;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-content-list {
    gap: 15px 15px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-content-list {
    &[data-type="tipo2"] {
      max-width: 630px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-content-list {
    display: inline-block;

    &--item {
      margin: 5px 0;
    }
  }
}