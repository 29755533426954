/* --- CATEGORIES TAB CONTENT ITEM SLIDER --- */
@import '../../../../../styles/settings/colors';

.categories-tab-content-item-slider {
  &--controls {
    display: none;
  }

  &--list,
  &--element {
    display: none;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
    vertical-align: top;
    will-change: transform;
  }

  &--list {
    overflow: hidden;

    .content {
      display: inline-flex;
      justify-content: center;
      flex-flow: row nowrap;
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }
  
  &--element {
    align-items: flex-start;
    background-color: transparent;
    border-radius: 8px;
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 25px;
    
    &:before {
      background-color: $white-700;
      border-radius: 8px;
      content: '';
      height: calc(100% - 10px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 10px);
    }
  }
}

/* --- QUERIES --- */
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .categories-tab-content-item-slider {
    &--element,
    &--list {
      display: inline-block;
    }

    &--element {
      background-color: $white-700;
      border: 5px solid $white-900;
      border-radius: 12px;
      padding: 30px 25px 35px;

      &:before {
        display: none;
      }
    }
  }
}