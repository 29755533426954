/* --- TEXT SLIDER HIGHLIGHT GALLERY --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/animations';

@import '../../../styles/tools/mixins';

.text-slider-highlight-gallery {
  height: 100%;
  overflow: hidden;
  right: calc(((100vw - #{$page-width}) / 2) * -1);
  position: absolute;
  top: 0;
  width: calc(((100vw - #{$page-width}) / 2) + 55%);
  vertical-align: top;

  &--controls {
    bottom: 0;
    left: 0;
    padding: 15px;
    position: absolute;
    width: 100%;
    z-index: 4;

    &-btn {
      background-color: $white-900;
      border-radius: 3px;
      border: none;
      float: left;
      height: 6px;
      margin: 0 3px;
      outline: none;
      opacity: 0.4;
      transition: all 400ms $base-ease;
      width: 31px;

      &[data-active="true"] {
        opacity: 1;
      }
    }
  }

  &--item {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    img,
    picture {
      float: left;
      height: 100%;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
    
    picture {
      width: 100%;
      will-change: transform;

      img {
        object-fit: cover;
        width: 100%;
      }

      &:after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0) 56.67%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &--list {
    float: left;
    height: 100%;
    position: relative;
    width: 100%;
    vertical-align: top;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .text-slider-highlight-gallery {
    right: 0;
    width: 55%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .text-slider-highlight-gallery {
    width: 50%;

    &--controls {
      &-btn {
        height: 5px;
        width: 25px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .text-slider-highlight-gallery {
    order: 1;
    height: 210px;
    position: relative;
    width: 100%;
    
    &--controls {
      height: 5px;
      text-align: center;

      &-btn {
        float: none;
      }
    }
  }
}
