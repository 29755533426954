/* --- HIGHLIGHTS SLIDER --- */

.list-highlights {
  &--slider {
    overflow: hidden;
    width: 100%;

    &--content {
      align-items: stretch;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      margin: 0;
      float: left;
      position: relative;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-highlights {
    &--slider {
      overflow: unset;
    }
  }
}
