/* --- CARROUSEL TIME LINE ---- */
@import '../../styles/settings/settings';
@import '../../styles/tools/mixins';

@import '../../styles/components/typography';

.carrousel-timeline {
  float: left;
  margin: 0 0 80px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--title {
    float: left;
    margin: 60px 107px 40px;
    position: relative;
    width: calc(100% - (107px * 2));
    z-index: 2;

    .title-main {
      @extend %title-1;
      color: $purple-900;
      float: left;
      line-height: 1em;
      vertical-align: top;

      &:before {
        background-color: $red-900;
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .carrousel-timeline {
    &--title {
      margin: 55px 85px 30px;
      width: calc(100% - (85px * 2));
    }

    &:before {
      display: none;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .carrousel-timeline {
    &--title {
      margin: 50px 20px 20px;
      width: calc(100% - (20px * 2));
    }

    .wrapper {
      padding: 0;
    }
  } 
}