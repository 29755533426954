/* --- PHOTO SPECIAL INFO --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.photo-special {
  &--info {
    float: left;
    padding: 90px 80px 90px 110px;
    position: relative;
    text-align: left;
    width: 50%;
    vertical-align: top;

    .description,
    .title {
      float: left;
      position: relative;
      width: 100%;
    }

    .description {
      @extend %text;
      color: $purple-100;
      margin: 0 0 25px;
    }

    .title {
      @include font($font-default, 34px, 400, $purple-900);
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .photo-special {
    &--info {
      padding: 60px 40px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .photo-special {
    &--info {
      padding: 60px 40px;
      width: calc(50% - 15px);

      .title {
        @include rem('font-size', 20px);
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 640px) {
  .photo-special {
    &--info {
      order: 1;
      padding: 35px 20px 30px;
      width: 100%;
    }
  }
}
