/* --- MISSION VISION VALUES --- */
@import '../../../styles//settings/settings';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/icons';
@import '../../../styles/components/typography';

.mission-vision-values {
  &--item {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    .body,
    .title {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:empty {
        display: none;
      }
    }

    .body {
      p {
        @extend %text;
        color: $purple-100;
        line-height: 1.8em;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin: 0;
        list-style: none;

        li {
          @extend %text;
          color: $purple-100;
        }
      }
    }

    .title {
      @extend %title-1;
      color: $purple-900;
      margin: 0 0 20px;

      &:before {
        background-color: $red-900;
      }
    }

    &[data-type="1"] {
      background-color: $purple-700;
      border-radius: 80px 0 0 0;
      grid-row: 1;
      grid-column: 1;
      margin-bottom: 65px;
      padding: 120px 60px 70px;

      .body {
        p {
          color: $white-900;
        }
      }

      .title {
        color: $white-900;

        &:before {
          background-color: $white-900;
        }
      }

      &:before {
        border: 2px solid $purple-50;
        border-right: none;
        border-top: none;
        bottom: -67px;
        content: '';
        height: 65px;
        right: 0;
        position: absolute;
        width: calc(100% - 30px);
        z-index: 1;
      }
    }

    &[data-type="2"] {
      grid-row: 2;
      grid-column: 1;
      padding: 75px 60px 0;
    }

    &[data-type="3"] {
      background-color: $white-700;
      border-radius: 0 0 80px 0;
      grid-row: 1 / 4;
      grid-column: 2;
      margin: 100px 0 0;
      padding: 0 85px 0;

      .body {
        padding: 0 0 80px;

        ul {
          padding: 0;

          li {
            @extend %icon-check;
            margin: 16px 0;
            position: relative;

            b {
              @include font($font-default, 18px, 600, $purple-900);
              float: left;
              position: relative;
              width: 100%;
              vertical-align: top;
            }

            &:before {
              @include rem('font-size', 15px);
              @extend %icon;
              @extend %icon-check;
              color: $red-900;
              right: calc(100% + 10px);
              position: absolute;
              top: 5px;
            }
          }
        }
      }

      .title {
        margin-top: 75px;
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .mission-vision-values {
    &--item {
      .body p,
      .body ul li {
        @include rem('font-size', 13px);
      }

      &[data-type="1"] {
        margin: 0;
        padding: 90px 40px 60px;
      }

      &[data-type="2"] {
        padding: 95px 40px 0;
      }

      &[data-type="3"] {
        margin: 15px 0 0;
        padding: 0 55px 0;

        .body ul li:before {
          @include rem('font-size', 10px);
        }

        .body ul li b {
          @include rem('font-size', 16px);
        }

        .title {
          margin-top: 85px;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .mission-vision-values {
    &--item {
      &[data-type="1"],
      &[data-type="3"] {
        border-radius: 0;
        padding: 55px 20px 35px;
      }

      &[data-type="1"] {
        order: 1;

        .body p {
          @include rem('font-size', 14px);
        }

        &:before {
          display: none;
        }
      }

      &[data-type="2"] {
        order: 2;
        padding: 75px 20px 35px;
      }

      &[data-type="3"] {
        order: 3;
        margin: 0;

        .body {
          padding: 0 0 30px;
          ul {
            li {
              margin-top: 35px;

              &:before {
                left: 0;
                top: -25px;
              }

              &:first-child {
                margin-top: 60px;
              }
            }
          }
        }

        .title {
          margin: 0;
        }
      }
    }
  }
}
