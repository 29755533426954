/* --- INPUT --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.input-control {
  float: left;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;

  .label,
  input {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .label {
    @include font($font-default, 11px, 600, $white-900);
    line-height: 1em;
    margin: 0 0 5px 7px;
    text-transform: uppercase;
  }

  textarea,
  input {
    @include font($font-default, 11px, 600, $white-900);
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;
    border-radius: 4px;
    height: 40px;
    outline: none;
    padding: 7px;
    width: 100%;

    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      border-radius: 4px;

      &::placeholder {
        color: $white-900;
      }
    }

    &:focus,
    &:active,
    &:hover {
      border-color: $white-900;
    }
  }

  input {
    &[type="email"] {
      border-radius: 4px;
    }
  }

  textarea {
    height: auto;

    & + .info {
      @include font($font-default, 10px, 600, $white-900);
      float: left;
      margin: 10px 0 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &[data-type="check"] {
    display: inline-flex;

    input {
      height: 100%;
      left: -15px;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: calc(100% + 15px);
      z-index: 2;

      & + .icon {
        float: left;
        height: 13px;
        position: relative;
        width: 13px;
        vertical-align: top;

        &:before,
        &:after {
          @include box-display;
          left: 0;
          position: absolute;
        }

        &:before {
          color: $white-900;
          display: none;
          font-size: 5px;
          left: 3px;
          top: 4px;
          z-index: 1;
        }
        
        &:after {
          background-color: $red-900;
          border: 1px solid $red-800;
          border-radius: 3px;
          height: 100%;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      &:checked + .icon {
        &:before {
          display: inline-block;
        }
      }
    }

    .label {
      font-weight: 400;
      margin: 1px 0 0 5px;
      order: 2;
      text-transform: none;
    }

    .icon {
      order: 1;
    }
  }

  &[data-type="radio"] {
    input {
      display: none;

      &:checked {
        & + .radio-icon {
          &:before {
            border-color: $red-900;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
    
    .label {
      margin: 2px 0 0;
      padding: 0 0 0 20px;
      text-transform: none;
      width: auto;
    }

    .radio-icon {
      left: 0;
      height: 100%;
      line-height: 1em;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      vertical-align: top;

      &:before,
      &:after {
        border-radius: 100%;
        content: '';
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 400ms $base-ease;
      }

      &:before {
        background-color: $white-900;
        border: 1px solid transparent;
        height: 13px;
        width: 13px;
      }

      &:after {
        background-color: $red-900;
        height: 9px;
        left: 3px;
        opacity: 0;
        width: 9px;
        z-index: 1;
      }
    }
  }

  &[data-type="money"] {
    .info {
      @include font($font-default, 12px, 500, $purple-400);
      float: left;
      margin: 7px 0 0;
      line-height: 1em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &[data-type="select"] {
    z-index: 2;
  }

  &[data-error="true"] {
    &[data-type="phone"],
    &[data-type="cnpj"],
    &[data-type="email"],
    &[data-type="text"],
    &[data-type="textarea"],
    &[data-type="money"] {
      textarea,
      input {
        border-color: $red-900;
      }
    }

    &[data-type="select"] {
      .select {
        &-title {
          border: 1px solid $red-900;
        }
      }
    }

    &:focus {
      &:required {
        border-color: $red-900;
      }
    }
  }

  &[data-theme="white"] {
    .label {
      color: $black-900;
    }

    textarea,
    input {
      background-color: $white-900;
      color: $black-900;
    }

    textarea {
      & + .info {
        color: $purple-400;
      }
    }
  }
}
