/* --- WEBDOOR --- */
@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.webdoor {
  &--content {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.58) 43.47%, rgba(0, 0, 0, 0.18) 79.58%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
      vertical-align: top;
    }
  }
}
