/* --- WEBDOOR INTERNAL --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/tools/mixins';

.webdoor-internal {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-flow: row wrap;
  float: left;
  height: 240px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--background {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    img {
      float: left;
      object-fit: cover;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 1;
    }

    &:after,
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &:before {
      background-color: $purple-900;
      mix-blend-mode: color;
      opacity: 0.7;
    }

    &:after {
      background-color: rgba($black-900, 0.2);
    }
  }

  &--title {
    @include font($font-default, 44px, 500, $white-900);
    letter-spacing: 0.05em;
    padding: 20px 0 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;

    &:before,
    &:after {
      background-color: $red-900;
      border-radius: 2px;
      content: '';
      height: 4px;
      position: absolute;
      top: calc(50% + 10px);
      transform: translateY(-50%);
      width: 37px;
    }

    &:before {
      left: -55px;
    }

    &:after {
      right: -55px;
    }
  }

  &:empty {
    display: none;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor-internal {
    height: 90px;

    &--title {
      @include rem('font-size', 22px);
      padding: 0;

      &:after,
      &:before {
        top: 50%;
        width: 20px;
      }

      &:before {
        left: -30px;
      }

      &:after {
        right: -30px;
      }
    }
  }
}
