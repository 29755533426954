/* --- COLORS --- */

$black-100: #4D555E;
$black-700: #2F3237;
$black-750: #2E3034;
$black-900: #222;

$blue-100: #1857FA;

$red-400: #D34651;
$red-550: #E26682;
$red-800: #A52F39;
$red-850: #B90F35;
$red-900: #CD163F;

$gray-100: #F8F8FC;
$gray-400: #959B9F;
$gray-900: #98A3B9;

$green-100: #33C24A;
$green-600: #7A9698;
$green-900: #6B8A8C;

$purple-10: #C5D3DF;
$purple-50: #C7D5E1;
$purple-70: #677782;
$purple-100: #556677;
$purple-400: #888AB1;
$purple-500: #746B97;
$purple-550: #62659B;
$purple-600: #716F96;
$purple-650: #3E3F70;
$purple-700: #3F3F70;
$purble-750: #424573;
$purple-900: #282B60;

$white-100: #CCC5AB;
$white-300: #F8F8F8;
$white-400: #D5E0E9;
$white-700: #F1F1F1;
$white-800: #FAFAFA;
$white-900: #FFF;
