/* --- PHOTO SPECIAL IMAGE --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.photo-special {
  &--image {
    float: left;
    position: relative;
    width: 50%;
    vertical-align: top;

    .credits,
    .image {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
    }

    .credits {
      @include font($font-default, 12px, 500, $purple-100);
      line-height: 1.4em;
      padding: 20px 80px;
      text-align: left;
    }

    .image {
      align-items: center;
      border-radius: 0 0 90px 0;
      display: inline-flex;
      justify-content: center;
      height: auto;
      left: -15px;
      overflow: hidden;
      width: calc(100% + 15px);

      img {
        object-fit: cover;
        float: left;
        height: 100%;
        width: auto;
      }
    }

    .parallax-outer,
    .parallax-inner {
      width: 100%;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .photo-special {
    &--image {
      padding-left: 15px;
      width: calc(50% + 15px);

      .parallax-inner {
        transform: none !important;
      }

      .credits {
        padding: 15px 30px;
      }

      .image {
        left: 0;
        width: 100%;
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 640px) {
  .photo-special {
    &--image {
      order: 2;
      left: 0;
      padding: 0;
      width: calc(100% + 40px);
      
      .image {
        border-radius: 0;
        overflow: hidden;
        width: 100%;

        img {
          height: 100%;
          float: left;
          object-fit: cover;
          object-position: top;
          position: relative;
          width: 100%;
        }
      }
    }
  }
}