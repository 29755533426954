/* --- LIST STATIC --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/settings/grid';

@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.list-static {
  background-color: $purple-900;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--background,
  &--title {
    float: left;
    width: 100%;
    vertical-align: top;
  }

  &--background {
    left: calc((100vw - #{$page-width}) / 2 - 15px);
    height: 325px;
    overflow: hidden;
    position: absolute;
    width: calc((100vw + (100vw - #{$page-width}) / 2) + 15px);

    .parallax-outer,
    .parallax-inner {
      float: left;
      height: 100%;
      position: relative;
      width: 100%;
    }

    picture {
      float: left;
      height: 100%;
      position: relative;
      width: 100%;

      img {
        float: left;
        object-fit: cover;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    &:after {
      background-color: $purple-900;
      background-blend-mode: multiply;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.42;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &--title {
    background-color: $purple-900;
    list-style: none;
    margin: 190px 0 0;
    padding: 80px 0 90px;

    &:after,
    &:before {
      background-color: $purple-900;
      content: '';
      height: 100%;
      left: -15px;
      position: absolute;
      top: 0;
      width: 15px;
    }

    &:after {
      left: initial;
      right: -20px;
      width: 20px;
    }
  }

  .wrapper {
    display: inline-block;
    padding: 0;
    text-align: left;
    vertical-align: top;
    z-index: 3;

    &:before {
      @include box-display;
      background-color: $white-900;
      content: '';
      height: 3px;
      right: 15px;
      outline: none;
      position: absolute;
      top: 200px;
      width: calc(100vw - 15px);
      z-index: 2;
    }
  }

  &[data-type="tipo2"] {
    background-color: $red-900;

    .list-static {
      &--background {
        picture {
          z-index: 1;
        }

        &:after {
          background-color: $red-900;
          z-index: 2;
        }
      }

      &--title {
        background-color: $red-900;
        display: inline-block;
        height: auto;
        padding: 80px 290px 0 20px;
    
        .description,
        .title {
          float: left;
          position: relative;
          width: 100%;
          vertical-align: top;
        }
    
        .description {
          @extend %text;
          color: $white-900;
          margin: 0;
        }
    
        .title {
          @extend %title-1;
          color: $white-900;
          margin: 0 0 15px;
    
          &:before {
            display: none;
          }
        }
    
        & + .list-static--list {
          margin: 0;
        }
    
        &:before,
        &:after {
          background-color: $red-900;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-static {
    &--background {
      height: 200px;
      left: initial;
      position: relative;
      top: initial;
      width: 100vw;

      img {
        height: auto;
        width: 100%;
      }
    }

    .wrapper {
      display: inline-block;
      padding: 0 25px;
      
      &:before {
        right: 0;
        top: 10px;
        width: 100vw;
      }
    }

    &[data-type="tipo2"] {
      .list-static {
        &--title {
          margin: 0;
          padding: 40px 220px 0 20px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-static {
    &--background {
      height: 110px;
    }

    &[data-type="tipo2"] {
      .list-static {
        &--title {
          padding: 40px 0 0;
        }
      }
    }
  }
}
