/* --- ANIMATIONS --- */

$base-duration: 600ms;
$base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);

/* ANIMATION */
@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes header-submenu {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
 @keyframes slide-in-bck-center {
  0% {
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}
