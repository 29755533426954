/* --- PRODUCT CREDITS JURIDIC ITEM --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/typography';

.product-investment-item {
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  .characteristics,
  .description,
  .title {
    float: left;
    margin: 0;
    position: relative;
    width: 100%;
    vertical-align: top;
    user-select: none;
  }

  .btn {
    &-border {
      border-color: $purple-50;
      margin-top: 10px;

      .icon,
      .text {
        color: $black-900;
        float: left;
        position: relative;
        vertical-align: top;
      }

      .icon {
        @include rem('font-size', 13px);
        margin: 0 5px 0 0;
      }

      .text {
        @include font($font-default, 13px, 600, $purple-900);
      }
    }
  }

  .characteristics {
    @extend %text;
    color: $purple-100;
    font-weight: 500;

    ul {
      float: left;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
      vertical-align: top;

      li {
        @include font($font-default, 14px, 500, $black-700);
        float: left;
        margin: 7px 0;
        padding: 0 0 0 15px;
        position: relative;
        width: 100%;
        vertical-align: top;

        &:before {
          background-color: $red-900;
          border-radius: 100%;
          content: '';
          height: 6px;
          left: 0;
          position: absolute;
          top: calc(50% - 1px);
          transform: translate(0, -50%);
          width: 6px;
        }
      }
    }

    p {
      margin-top: 0;
    }
  }

  .description {
    @extend %text;
    color: $purple-100;
    font-weight: 500;
  }

  .title {
    .name,
    .subtitle {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .name {
      @include font($font-default, 26px, 600, $purple-900);
      letter-spacing: -0.01em;
    }

    .subtitle {
      @include font($font-default, 12px, 600, $gray-400);
      margin: 0 0 5px;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-investment-item {
    .characteristics,
    .description {
      @include rem('font-size', 13px);
    }

    .characteristics {
      ul {
        li {
          @include rem('font-size', 13px);

          &:before {
            top: calc(50% + 1px);
          }
        }
      }
    }

    .title {
      .name {
        @include rem('font-size', 18px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .product-investment-item {
    .title {
      .name {
        @include rem('font-size', 16px);
      }
    }
  }
}
