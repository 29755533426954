/* --- FOOTER ADDRESS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.footer {  
  &--address {
    float: left;
    padding: 0 0 40px;
    position: relative;
    text-align: left;
    width: 60%;
    vertical-align: top;

    &--item {
      float: left;
      padding: 30px 50px 0 10px;
      position: relative;
      width: 50%;
      vertical-align: top;

      .list,
      .title {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .list {
        list-style: none;
        margin: 0;
        padding: 0;

        &--item {
          float: left;
          margin: 0 0 5px;
          position: relative;
          padding: 0 0 0 15px;
          width: 100%;
          vertical-align: top;

          .icon {
            color: $red-900;
            position: absolute;
            top: 4px;

            &.icon-piker {
              @include rem('font-size', 11px);
              left: 2px;
            }

            &.icon-phone {
              @include rem('font-size', 12px);
              left: 1px;
              top: 4px;
            }
          }

          .text {
            @include font($font-default, 12px, 400, $black-100);
            line-height: 1.6em;
            text-decoration: none;
          }
        }
      }

      .title {
        @include font($font-default, 13px, 500, $red-900);
        margin: 0 0 10px;
      }
      
      &:last-child {
        margin: 0;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .footer {
    &--address {
      padding: 30px 10px 0;
      width: 50%;

      &--item {
        padding: 0 30px 30px 0;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer {
    &--address {
      padding: 0;
      width: 55%;

      &--item {
        padding: 0 25px 30px 0;

        .list {
          &--item {
            .text {
              @include rem('font-size', 10px);
            }
          }
        }

        .title {
          @include rem('font-size', 11px);
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .footer {
    &--address {
      padding: 0;
      width: 100%;

      &--item {
        padding: 15px 10px;
      }
    }
  }
}