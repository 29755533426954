/* --- LIST CARROUSEL VIDEOS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/components/typography';

@import '../../../styles/tools/mixins';

.list-carrousel-video {
  float: left;
  margin: 40px 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--title {
    display: inline-block;
    float: none;
    max-width: 730px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .title {
      @extend %title-1;
      color: $purple-900;

      &:before {
        background-color: $red-900;
      }
    }
  }

  .wrapper {
    display: inline-block;
    text-align: center;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-video {
    &--title {
      max-width: 450px;
    }

    .wrapper {
      padding: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-carrousel-video {
    margin: 20px 0;
    
    &--title {
      padding: 0 20px;
    }
  }
}