/* --- TAB SUBMENU --- */
@import '../../styles/settings/settings';
@import '../../styles/tools/mixins';

.tab-submenu {
  border: none;
  border-bottom: 1px solid $purple-50;
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &:empty {
    display: none;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tab-submenu {
    .wrapper {
      padding: 0;
    }
  }
}