/* --- LIST CARROUSEL PHOTO CONTENT --- */

.list-carrousel-photo-content {
  float: none;
  margin: 0;
  max-width: 730px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--list {
    float: none;
    height: calc(410px + 40px);
    overflow: hidden;
    position: relative;
    width: 100%;
    vertical-align: top;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-photo-content {
    max-width: none;
    width: calc(100% - (75px * 2));

    &--list {
      height: calc(320px + 40px);
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-carrousel-photo-content {
    width: 100%;

    &--list {
      height: calc(210px + 40px);
    }
  }
}
