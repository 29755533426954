/* --- SELECT ITEM --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.select-item {
  border: none;
  border-bottom: 1px solid $white-700;
  float: left;
  height: 40px;
  line-height: 1em;
  padding: 10px 8px;
  position: relative;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    @include font($font-default, 12px, 500, $purple-100);
    float: left;
    margin: 0;
    position: relative;
    transition: all 400ms $base-ease;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    .text {
      color: $red-900;
    }
  }

  &:hover {
    .text {
      color: $red-900;
    }
  }
}