/* --- LIST PRODUCTS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/grid';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.list-products {
  background-color: $white-900;
  float: left;
  margin: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  z-index: 4;

  &--list {
    float: left;
    list-style: none;
    margin: 0;
    padding: 25px 0 0;
    position: relative;
    text-align: center;
    top: 25px;
    width: 100%;
  }

  .wrapper {
    display: inline-block;
    position: relative;

    &:before {
      border: 2px solid $purple-50;
      border-right: none;
      border-top: none;
      content: '';
      height: 70%;
      left: 50px;
      position: absolute;
      width: calc(100% + ((100vw - #{$page-width}) / 2));
    }
  }
}

/*--- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-products {
    &--list {
      padding: 15px 20px 0;
    }

    .parallax-inner {
      transform: none !important;
    }

    .wrapper {
      &:before {
        display: none;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-products {
    &--list {
      padding: 25px 0;
      top: 0;
    }

    .wrapper {
      padding: 0 20px;
    }
  }
}