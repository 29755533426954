/* --- LIST STATIC LIST --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/grid';

@import '../../../../styles/tools/mixins';

.list-static {
  &--list {
    align-items: flex-start;
    background-color: $purple-900;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    float: left;
    list-style: none;
    margin: 190px 0 0;
    padding: 80px 0 90px;
    position: relative;
    width: 100%;
    vertical-align: top;

    &:after,
    &:before {
      background-color: $purple-900;
      content: '';
      height: 100%;
      left: -15px;
      position: absolute;
      top: 0;
      width: 15px;
    }

    &:after {
      left: initial;
      right: -20px;
      width: 20px;
    }
  }

  &[data-type="tipo2"] {
    .list-static {
      &--list {
        align-items: flex-start;
        background-color: $red-900;

        &:after,
        &:before {
          background-color: $red-900;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-static {
    &--list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0;
      padding: 20px 20px 40px;

      &:before {
        display: none;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-static {
    &--list {
      display: inline-block;
      padding: 40px 5px 30px;
    }
  }
}
