/* --- LIST CARROUSEL VIDEO GALLERY --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-carrousel-video-gallery {
  display: inline-block;
  float: none;
  overflow: hidden;
  max-width: calc(260px * 4);
  position: relative;
  width: 100%;
  vertical-align: top;
  will-change: transform;

  &--controls {
    float: left;
    margin: 0 0 30px;
    position: relative;
    text-align: right;
    width: 100%;
    vertical-align: top;

    .btn {
      &.btn-border {
        border-color: $purple-50;
        height: 32px;
        margin: 0 2px;
        width: 32px;

        .icon {
          @include rem('font-size', 12px);
          color: $purple-900;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }
    }
  }

  &--list {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    .content {
      display: inline-flex;
      justify-content: center;
      flex-flow: row nowrap;
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
      will-change: transform;
    }
  }

  &--scroll {
    display: none;
  }

  &[data-controls="r"] {
    .list-carrousel-video-gallery {
      &--controls {
        .btn {
          &.btn-border {
            &:last-child {
              opacity: 0.6;
              pointer-events: none;
            }
          }
        }
      }
    }
  }

  &[data-controls="l"] {
    .list-carrousel-video-gallery {
      &--controls {
        .btn {
          &.btn-border {
            &:first-child {
              opacity: 0.6;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-carrousel-video-gallery {
    float: right;
    margin: 0 0 0 40px;
    overflow: initial;
    width: calc(100% - 40px);

    &--controls {
      display: none;
    }

    &--list {
      margin-top: 20px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-video-gallery {
    &--list {

    }
  }
}
