/* --- ICONS --- */
%icon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chat:before {
  content: "\e90f";
}
.icon-arrow-left-l:before {
  content: "\e90d";
}
.icon-arrow-right-l:before {
  content: "\e90e";
}
%icon-check,
.icon-check:before {
  content: "\e90c";
}
.icon-file:before {
  content: "\e90b";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-top:before {
  content: "\e907";
}
.icon-arrow-left:before {
  content: "\e908";
}
.icon-logo:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-cal-money:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-light:before {
  content: "\e903";
}
.icon-menu:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-piker:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\ea91";
}
.icon-linkedin:before {
  content: "\eac9";
}
