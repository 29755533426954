/* --- REPORTS ITEM --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

.reports {
  &--item {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;

    &--list,
    &--subitem {
      float: left;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      vertical-align: top;
    }

    .category {
      @include font($font-default, 14px, 600, $red-900);
      float: left;
      margin: 20px 0 10px;
      position: relative;
      text-transform: uppercase;
      width: 100%;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .reports {
    &--item {
      .category {
        @include rem('font-size', 12px);
        margin: 20px 0 5px;
      }
    }
  }
}