/* --- HEADER --- */
@import '../../styles/components/animations';
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/components/forms';

@import '../../styles/tools/mixins';

.header {
  @include box-display;
  background-color: $white-900;
  float: left;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;
  z-index: 99;

  &--logo,
  &--item,
  &--user {
    @include box-display;
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: top;
  }

  &--logo {
    padding: 0 10px 0 0;

    .logo,
    .open {
      @include box-display;
      float: left;
      position: relative;
    }

    .logo {
      top: 1px;
      width: 140px;

      img {
        @include box-display;
        float: left;
        height: auto;
        position: relative;
        width: 100%;
      }
    }

    .open {
      @extend %btn-clear;
      display: none;
      margin: 0 20px 0 0;
      padding: 0;
      outline: none;
      top: -1px;

      .icon {
        @include box-display;
        height: 22px;
        position: relative;
        width: 30px;

        &.icon-menu {
          @include rem('font-size', 22px);
        }

        &.icon-close {
          @include rem('font-size', 18px);
          left: 3px;
          top: 4px;
        }
      }
    }
  }

  &--user {
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
    flex-flow: row wrap;
    float: right;
    text-align: right;
    width: 40%;

    .link {
      float: none;
      font-weight: 600;
      margin: 0 5px 0;
      width: auto;
    }
  }

  .wrapper {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    height: 100%;
    padding: 0 15px;
    text-align: left;
    vertical-align: top;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .header {
    height: 60px;

    &--logo {
      padding: 17px 0;
      top: 1px;
      z-index: 3;

      .logo {
        width: 89px;
      }

      .open {
        display: inline-block;
      }
    }

    &--user {
      padding: 13px 0;
      z-index: 1;

      .btn {
        @include rem('font-size', 11px);
      }
    }

    .wrapper {
      display: inline-block;
    }

    &[data-open="true"] {
      z-index: 999999;

      .header {
        &--logo {
          position: fixed;
          
          .logo {
            display: none;
          }
        }

        &--menu {
          left: 0;
          position: fixed;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .header {
    &--user {
      .link {
        display: none;
      }
    }
  }
}
