/* --- TEXT SLIDER HIGHLIGHT --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/grid';

@import '../../styles/components/typography';

.text-slider-highlight {
  background-color: $purple-900;
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--info {
    align-content: center;
    align-items: center;
    display: inline-flex;
    float: left;
    height: 660px;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 15px 140px 15px 15px;
    position: relative;
    width: 45%;

    .description,
    .title {
      float: left;
      margin: 0 0 20px;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .description {
      @extend %text;
      color: $white-900;
    }

    .title {
      @extend %title-1;
      color: $white-900;

      &:before {
        background-color: $white-900;
      }
    }
  }

  .wrapper {
    display: inline-block;
    text-align: left;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .text-slider-highlight {
    &--info {
      height: auto;
      padding: 70px 20px;
      width: 50%;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .text-slider-highlight {
    &--info {
      order: 2;
      padding: 50px 20px 40px;
      width: 100%;
    }

    .wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      padding: 0;
    }
  }
}