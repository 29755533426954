/* --- LIST BENEFITS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-benefits {
  float: left;
  margin: 20px 0 0;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--content,
  &--item,
  &--list {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--content {
    background-color: $gray-100;
    max-width: 790px;
    float: none;
    padding: 25px 35px;
    text-align: left;

    .title {
      @include font($font-default, 22px, 500, $purple-900);
      float: left;
      margin: 0;
      line-height: 1.7em;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &--item {
    margin: 0;

    .icon {
      @include rem('font-size', 9px);
      border: 1px solid $red-900;
      border-radius: 100%;
      color: $red-900;
      height: 20px;
      left: 0;
      position: absolute;
      top: 4px;
      width: 20px;

      &:before {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      @include font($font-default, 16px, 400, $black-700);
      float: left;
      margin: 0;
      line-height: 1.7em;
      padding: 0 0 0 30px;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 30px 30px;
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
  }

  .wrapper {
    display: inline-block;
    float: none;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-benefits {
    &--content {
      .title {
        @include rem('font-size', 16px);
        font-weight: 600;
      }
    }

    &--item {
      .icon {
        @include rem('font-size', 5px);
        height: 16px;
        width: 16px;
      }

      .text {
        @include rem('font-size', 14px);
      }
    }

    &--list {
      gap: 20px 20px;
      margin: 10px 0 0;
    }

    .wrapper {
      padding: 0 35px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-benefits {
    &--list {
      display: inline-block;
    }

    &--item {
      margin: 10px 0;
    }

    .wrapper {
      padding: 0;
    }
  }
}