/* --- PRODUCT CREDITS FISIC ITEM CONTENT GALLERY ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

.product-credits-fisic-item-content-gallery-item {
  align-items: flex-start;
  background-color: transparent;
  display: inline-flex;
  flex-flow: column wrap;
  float: left;
  justify-content: space-between;
  margin: 0;
  padding: 20px;
  position: relative;
  text-align: left;
  width: 100%;

  .info,
  .city {
    float: left;
    margin: 0;
    line-height: 1.6em;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  .info {
    .address,
    .link,
    .name {
      float: left;
      margin: 0;
      line-height: 1.6em;
      position: relative;
      width: 100%;
      vertical-align: top;
      user-select: none;
    }

    .address,
    .link {
      @include font($font-default, 13px, 400, $white-900);
      user-select: none;
    }

    .address {
      margin: 0 0 10px;
      white-space: pre-line;
    }

    .name {
      @include font($font-default, 18px, 500, $white-900);
      margin: 0 0 10px;
    }

    .link {
      overflow: hidden;
      pointer-events: auto;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .city {
    margin: 10px 0 0;
    user-select: none;

    .icon {
      @include rem('font-size', 16px);
      color: $white-900;
      left: 0;
      position: absolute;
      top: calc(50% - 1px);
      transform: translate(0, -50%);
    }

    .label {
      @include font($font-default, 13px, 400, $white-900);
      margin: 0 0 0 20px;
      width: calc(100% - 20px);
    }
  }

  &:before {
    background-color: $purble-750;
    content: '';
    border-radius: 8px;
    height: calc(100% - 10px);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10px);
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .product-credits-fisic-item-content-gallery-item {
    .name {
      @include rem('font-size', 14px);
    }
  }
}