/* --- HEADER MENU ITEM --- */
/* --- HEADER MENU --- */
@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header--menu {
  &--item,
  &--submenu {
    @include box-display;
    float: left;
    position: relative;
    vertical-align: top;
  }

  &--item {
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 100%;
    outline: none;
    z-index: 2;

    .link {
      align-items: center;
      background-color: transparent;
      border: none;
      display: inline-flex;
      font-weight: 600;
      float: left;
      justify-content: center;
      height: 100%;
      flex-flow: row wrap;
      margin: 0;
      line-height: 1em;
      
      .icon {
        @include rem('font-size', 6px);
        display: none;
        right: 10px;
        height: 6px;
        position: absolute;
        top: calc(50% + 3px);
        transform: translate(0, -50%);
        transform-origin: center;
        z-index: 2;

        &.icon-arrow-down {
          display: inline-block;
        }
      }

      .name {
        padding: 5px 0 0;
        width: 100%;
      }

      &.mobile {
        display: none;
      }
    }

    &:before {
      @include box-display;
      background-color: $red-900;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      top: 30px;
      transition: all 300ms $base-ease;
      width: 0;
    }

    &.last {
      border: none;
      border-top: 1px solid $white-400;
      display: none;
      margin: 15px 0 0;
    }

    &[data-active="true"] {
      > .link {
        color: $purple-900;

        .icon {
          color: $purple-900;
        }
      }

      &:before {
        width: 12px;
      }
    }

    &[data-submenu="true"] {
      > .link {
        padding: 0 30px 0 20px;
        outline: none;
      }

      &:before {
        z-index: 2;
      }
    }

    @include hover-supported() {
      overflow: initial;

      > .link {
        color: $purple-900;

        .icon {
          color: $purple-900;
          display: inline-block;

          &.icon-arrow-down {
            display: none;
          }
        }
      }

      .header--menu {
        &--submenu {
          animation: header-submenu 400ms $base-ease 0s forwards;
          display: inline-block;
        }
      }

      &:before {
        width: 12px;
      }
    }

    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }
  }

  &--submenu {
    background-color: $white-900;
    border-radius: 0 0 30px 0;
    display: none;
    list-style: none;
    left: 50%;
    opacity: 0;
    padding: 10px 25px 20px;
    position: absolute;
    top: 100%;
    transform: translate(-50%, 0);
    width: 255px;
    z-index: 1;

    &--item {
      @include box-display;
      float: left;
      padding: 0;
      position: relative;
      width: 100%;

      .link {
        color: $purple-100;
        padding: 20px 0;
        white-space: nowrap;

        &:before {
          @include box-display;
          background-color: $purple-50;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          transition: all 400ms $base-ease;
          top: 2px;
          width: 15px;
        }

        &.active {
          color: $purple-900;

          &:before {
            background-color: $red-900;
          }
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      @include hover-supported() {
        .link {
          color: $purple-900;

          &:before {
            background-color: $red-900;
          }
        }
      }
    }

    @include hover-supported() {
      opacity: 1;
      top: 100%;
    }

    &:empty {
      display: none !important;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .header--menu {
    background-color: $white-900;

    &--item {
      display: inline-block;
      height: auto;
      overflow: hidden;
      padding: 0;
      width: 100%;
      
      > .link {
        padding: 25px;
        text-align: left;
        z-index: 2;

        &.mobile {
          border: none;
          border-top: 1px solid $purple-50;
          display: inline-block;
          margin: 14px 0 0;
          padding-top: 30px;
        }

        &.active {
          & + ul {
            animation: none;
            left: 0;
            display: inline-block;
            opacity: 1;
          }
        }
      }

      &:before {
        left: 25px;
        top: 18px;
      }

      &.last {
        display: inline-block;
      }

      &[data-active="true"] {
        .header--menu {
          &--submenu {
            animation: none;
            left: 0;
            display: inline-block;
            opacity: 1;
          }
        }
      }

      &[data-submenu="true"] {
        overflow: initial;

        > .link {
          padding: 25px;

          .icon {
            right: 35px;
          }
        }
      }

      &[data-open="true"] {
        .link {
          .icon-arrow {
            &-down {
              display: none;
            }

            &-top {
              display: inline-block;
              top: calc(50% - 1px);
            }
          }
        }

        .header--menu--submenu {
          display: inline-block;
          left: 0;
          opacity: 1;
        }
      }

      &[data-open="false"] {
        .header--menu--submenu {
          display: none;
        }
      }
    }

    &--submenu {
      animation: none;
      background-color: $white-300;
      border-radius: 0;
      display: none;
      left: -100%;
      height: auto;
      overflow: hidden;
      padding: 0;
      position: relative;
      top: 0;
      transform: translate(0, 0);
      transition: left 400ms $base-ease;
      width: 100%;
      z-index: 1;

      &--item {
        > .link {
          padding: 20px 25px 25px;

          &:before {
            left: 24px;
            top: 10px;
          }
        }

        &:before {
          display: none;
        }
      }
    }
  }
}
