/* --- WEBDOOR --- */
@import '../../../styles/components/animations';
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.webdoor {
  &--item {
    height: 100%;
    position: absolute;
    width: 100%;
    will-change: transform;

    div {
      height: 100%;
      position: absolute;
      width: 100%;

      picture {
        float: left;
        height: 100%;
        position: relative;
        width: 100%;

        img {
          object-fit: cover;
          float: left;
          height: 100%;
          position: relative;
          width: 100%;
          vertical-align: top;
        }
      }
    }

    &:before {
      background-color: $blue-100;
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: screen;
      opacity: 0.4;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &.x {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
      height: 100%;
      will-change: transform;
      box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    }
  }
}
