/* --- TITLE TEXT EXPANDED --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.title-text-expanded {
  float: left;
  margin: 0 0 20px;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--content {
    align-items: stretch;
    display: inline-flex;
    float: none;
    justify-content: center;
    flex-flow: row nowrap;
    max-width: 730px;
    text-align: left;
    width: 100%;
    vertical-align: top;
  }

  &--text {
    float: left;
    position: relative;
    width: 50%;
    vertical-align: top;

    &.left,
    &.right {
      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.left {
      @include font($font-default, 18px, 500, $white-900);
      background-color: $purple-600;
      border-radius: 0 0 120px 0;
      line-height: 1.7em;
      overflow: hidden;
      padding: 60px 60px 60px 0;
    }

    &.right {
      @include font($font-default, 14px, 500, $purple-100);
      line-height: 1.7em;
      padding: 0 0 0 30px;
    }
  }

  .wrapper {
    text-align: center;
  }

  &:before {
    background-color: $purple-600;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(50vw - 120px);
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .title-text-expanded {
    &--content {
      display: inline-block;
      max-width: 660px;
    }

    &--text {
      &.left {
        @include rem('font-size', 14px);
        overflow: initial;
        padding: 40px 30px 40px 20px;

        &:before {
          background-color: $purple-600;
          content: '';
          height: 100%;
          left: -90px;
          position: absolute;
          top: 0;
          width: 90px;
        }
      }

      &.right {
        @include rem('font-size', 13px);
        padding: 0 30px;
      }
    }

    &:before {
      display: none;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .title-text-expanded {
    &--text {
      &.left,
      &.right {
        width: 100%;
      }

      &.left {
        border-radius: 0;
        margin: 0 0 20px;
        padding: 30px 20px;
      }

      &.right {
        padding: 0 20px;
      }
    }

    .wrapper {
      padding: 0;
    }
  }
}
