/* --- HIGHLIGHTS --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/typography';

.list-highlights {
  background-color: $white-900;
  float: left;
  padding: 70px 15px 80px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--info,
  &--image {
    float: left;
    position: relative;
    text-align: left;
    vertical-align: top;
  }

  &--info {
    width: 100%;

    .description,
    .title {
      &:empty {
        display: none;
      }
    }

    .description {
      @extend %text;
      color: $purple-100;
      margin: 0;
      max-width: 70%;
      width: 100%;
    }

    .title {
      @extend %title-1;
      color: $purple-900;
      margin-bottom: 20px;
      width: 100%;

      &:before {
        background-color: $red-900;
      }
    }
  }

  &--image {
    height: 100%;
    right: 0;
    overflow: hidden;
    padding: 0 0 0 20px;
    position: absolute;
    top: 0;
    width: calc((100% - #{$page-width}) / 2);

    picture {
      float: left;
      height: 100%;
      position: relative;
      vertical-align: top;
      width: 100%;

      img {
        float: left;
        height: 100%;
        object-fit: cover;
        position: relative;
        vertical-align: top;
        width: 100%;
      }

      &:after {
        background-color: $purple-900;
        content: '';
        height: 100%;
        left: 0;
        mix-blend-mode: normal;
        opacity: 0.42;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .parallax-outer,
  .parallax-inner {
    width: 100%;
  }

  .wrapper {
    display: inline-block;
  }

  &[data-type="tipo2"] {
    .list-highlights {
      &--image {
        &:after {
          background-color: $green-900;
          content: '';
          height: 100%;
          right: 0;
          position: absolute;
          top: 0;
          width: calc(100% - 20px);
          z-index: 9;
        }
      }
    }
  }

  &[data-type="tipo3"] {
    padding: 25px 15px 20px;

    .list-highlights {
      &--image {
        display: none;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-highlights {
    padding: 60px 40px;

    &--image {
      padding: 0;
      width: 40px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &--image {
      z-index: 1;
    }

    .parallax-inner {
      transform: none !important;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-highlights {
    overflow: hidden;
    padding: 60px 0 0;

    &--info {
      padding-right: 60px;

      .description {
        max-width: none;
      }
    }

    .wrapper {
      padding: 0 40px;
    }

    &[data-type="tipo3"] {
      .wrapper {
        padding: 0 20px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .list-highlights {
    padding: 60px 0 30px;

    &--info {
      padding: 0;
    }

    &--image {
      display: none;
    }

    .wrapper {
      padding: 0 20px;
    }
  }
}