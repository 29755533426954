/* --- PRODUCT CREDITS FISIC CONTENT GALLERY --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.product-credits-fisic-item-content-gallery {
  background-color: $purple-900;
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--list,
  &--scroll {
    float: left;
    padding: 20px 0 20px;
    width: 100%;
    will-change: transform;

    .content {
      display: inline-flex;
      justify-content: center;
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &--list {
    margin: 0 0 65px;
  }

  &--scroll {
    background-color: rgba(#000,  0.12);
    border-radius: 8px;
    bottom: 60px;
    left: 0;
    line-height: 1em;
    overflow: hidden;
    padding: 0 5px;
    position: absolute;

    .bar {
      background-color: $white-900;
      border-radius: 8px;
      cursor: pointer;
      float: left;
      height: 6px;
      position: relative;
      transform: translate3d(0, 0, 0);
      width: 200px;
      vertical-align: top;
      will-change: transform;
    }
  }

  .wrapper {
    display: inline-block;
    float: none;
    text-align: left;

    &[data-controls="l"] {
      .product-credits-fisic-content-controls .btn {
        &:nth-child(1) {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  
    &[data-controls="r"] {
      .product-credits-fisic-content-controls .btn {
        &:nth-child(2) {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .product-credits-fisic-item-content-gallery {
    &--list {
      overflow: hidden;
      margin: 0 0 20px;
    }

    &--scroll {
      display: none !important;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-fisic-item-content-gallery {
    .wrapper {
      padding: 0;
    }
  }
}