/* --- LIST PRODUCTS --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/grid';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.list-products {  
  &--item {
    align-items: stretch;
    display: inline-flex;
    float: none;
    max-height: 405px;
    margin: 0 9px;
    max-width: 304px;
    position: relative;
    width: calc(100% / 3 - 18px);

    .link,
    &--image,
    &--info {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
      z-index: 1;
    }

    &--image {
      border-radius: 8px;
      overflow: hidden;
      
      picture {
        float: left;
        height: 405px;
        position: relative;
        width: 100%;
        z-index: 1;
        vertical-align: top;

        img {
          float: left;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      &:before,
      &:after {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: all 400ms $base-ease;
        width: 100%;
      }

      &:after {
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.85) 23.53%, rgba(0, 0, 0, 0.21) 80%);
        opacity: 0.4;
        z-index: 2;
      }

      &:before {
        background-color: $red-850;
        mix-blend-mode: screen;
        opacity: 0.4;
        z-index: 3;
      }

      &:hover {
        &:before {
          opacity: 0;
        }
      }
    }

    &--info {
      bottom: 0;
      left: 0;
      padding: 20px;
      position: absolute;
      text-align: left;
      z-index: 2;

      .description,
      .text {
        float: left;
        margin: 0;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .description {
        @include font($font-default, 14px, 300, $white-900);
        line-height: 1.4em;
        margin: 10px 0 0;
      }

      .text {
        @include font($font-default, 18px, 400, $white-900);
        line-height: 1.3em;

        &:last-child {
          margin: 0 0 10px;
        }

        &:empty {
          display: none;
        }
      }
    }
  }
}

/*--- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-products {
    &--item {
      margin: 0 5px;
      max-width: none;
      width: calc((100% / 3) - 10px);

      &--image {
        height: 265px;

        &:before {
          display: none;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-products {
    &--item {
      background-color: $purple-700;
      border-radius: 6px 6px 38px 6px;
      margin: 5px 0;
      width: 100%;

      &--image {
        display: none;
      }

      &--info {
        bottom: initial;
        float: left;
        position: relative;

        .description {
          @include rem('font-size', 12px);
          margin: 10px 0 5px;
        }

        .text {
          @include rem('font-size', 16px);

          &:before {
            @include box-display;
            background-color: $red-900;
            content: '';
            display: block;
            margin: 0 0 12px;
            height: 2px;
            position: relative;
            width: 14px;
          }

          & + .text {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}