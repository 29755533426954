/* --- CARROUSEL TIMELINE ITEM --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.carrousel-timeline {
  &--item {
    background-color: $white-900;
    height: 100%;
    opacity: 0.4;
    padding: 0 5px;
    position: absolute;
    transition: opacity 400ms $base-ease, padding 100ms linear;
    width: 100%;
    will-change: transform;

    &--info {
      bottom: 0;
      left: 0;
      padding: 30px 65px;
      position: absolute;
      transition: padding 400ms $base-ease;
      width: 100%;
      z-index: 2;

      .year,
      .title {
        float: left;
        line-height: 1.2em;
        margin: 0;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .title {
        @include font($font-default, 20px, 500, $white-900);
      }

      .year {
        @include font($font-default, 40px, 600, $white-900);
        margin: 0 0 5px;
      }
    }

    .background {
      float: left;
      height: 100%;
      position: relative;
      pointer-events: none;
      width: 100%;
      vertical-align: top;
      z-index: 1;

      img {
        float: left;
        height: 100%;
        object-fit: cover;
        position: relative;
        width: 100%;
        z-index: 1;
      }

      &:after {
        background: linear-gradient(0deg, rgba(40, 43, 96, 0.67) 25%, rgba(40, 43, 96, 0.2) 71.11%);
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 400ms $base-ease;
        width: 100%;
        z-index: 2;
      }
    }

    &[data-active="true"] {
      opacity: 1;
      padding: 0 95px;

      .carrousel-timeline {
        &--item {
          &--info {
            bottom: 0;
            left: 0;
            padding: 30px 130px;
          }
        }
      }

      .background {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .carrousel-timeline {
    &--item {
      opacity: 1;
      padding: 0;

      &--info {
        padding: 30px 20px;

        .title {
          @include rem('font-size', 16px);
        }

        .year {
          @include rem('font-size', 26px);
        }
      }

      &[data-active="true"] {
        padding: 0;

        .carrousel-timeline {
          &--item {
            &--info {
              padding: 30px 20px;

              .title {
                @include rem('font-size', 16px);
              }

              .year {
                @include rem('font-size', 26px);
              }
            }
          }
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .carrousel-timeline {
    &--item {
      background-color: $purple-900;
      display: inline-flex !important;
      flex-flow: row wrap;

      .background,
      &--info {
        display: inline-flex;
      }

      .background {
        height: calc(100% - 95px);
        order: 1;
      }

      &--info {
        flex-flow: row wrap;
        order: 2;
        position: relative;

        .title,
        .year {
          width: 100%;
        }

        .title {
          @include rem('font-size', 15px);
        }

        .year {
          @include rem('font-size', 20px);
        }
      }

      &[data-active="true"] {
        .carrousel-timeline {
          &--item {
            &--info {
              bottom: initial;
              left: initial;
              padding: 25px 20px;

              .title {
                @include rem('font-size', 15px);
              }
      
              .year {
                @include rem('font-size', 20px);
              }
            }
          }
        }
      }
    }
  }
}
