/* --- SLIDER --- */
@import '../../styles/settings/colors';
@import '../../styles/tools/mixins';

@import '../../styles/components/animations';

.slider {
  @include box-display;
  float: left;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--item {
    display: none;
    transition: all 450ms $base-ease;

    &[data-current="true"] {
      display: inline-block;
    }
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &[data-type="limit"] {
    .slider {
      &--item {
        opacity: 0;
        transform: translate(100%, 0);

        &[data-current="true"] {
          animation: sliderBasicIn 400ms $base-ease 0s forwards;

          & + .slider--item {
            animation: sliderBasicIn 400ms $base-ease 0s forwards;

            & + .slider--item {
              animation: sliderBasicIn 400ms $base-ease 0s forwards;

              & + .slider--item {
                animation: sliderBasicIn 400ms $base-ease 0s forwards;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes sliderBasicIn {
  0% {
    opacity: 0;
    transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}