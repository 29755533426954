/* --- GRID --- */
@import './colors';
@import './../tools/mixins';

$page-width: 1140px;

html,
body,
body * {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

html,
body {
  @include box-display;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  background-color: $white-900;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}

script {
  display: none;
}

#root {
  @include box-display;
  float: left;
  min-height: 100vh;
  position: relative;
  width: 100%;
  vertical-align: top;
  z-index: 99;
}

.wrapper {
  @include box-display;
  display: block;
  margin: 0 auto;
  max-width: $page-width;
  padding: 0;
  position: relative;
  width: 100%;
}

.page {
  @include box-display;
  float: left;
  height: 100%;
  position: relative;
  width: 100%;
  vertical-align: top;

  .container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .wrapper {
    display: inline-block;
    padding: 0 25px;
  }
}