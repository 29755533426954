/* -- CARROUSEL TIMELINE CONTROLS --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

.carrousel-timeline {
  &--controls {
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    left: 0;
    height: 100%;
    pointer-events: none;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    .btn {
      align-items: center;
      background-color: $red-900;
      border-radius: 0;
      display: inline-flex;
      height: 100%;
      flex-flow: row wrap;
      justify-content: center;
      pointer-events: auto;
      width: 90px;
      z-index: 2;

      .icon {
        @include rem('font-size', 19px);
        color: $white-900;
        position: relative;
        vertical-align: top;
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .carrousel-timeline {
    &--controls {
      padding: 0 15px;

      .btn {
        width: 60px;

        .icon {
          @include rem('font-size', 15px);
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .carrousel-timeline {
    &--controls {
      align-items: flex-start;
      padding: 0;

      .btn {
        background-color: transparent;
        height: 40px;
        top: 65px;
        width: 40px;

        .icon {
          z-index: 2;
        }
        
        &:before {
          background-color: $black-700;
          content: '';
          height: 100%;
          left: 0;
          opacity: 0.8;
          position: absolute;
          transform: matrix(1, 0, 0, -1, 0, 0);
          width: 100%;
          z-index: 1;
        }
      }
    }
  }
}