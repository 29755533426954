/* --- REPORT LIST --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

.reports {
  &--list {
    float: left;
    margin: 0;
    list-style: none;
    padding: 0;
    width: 100%;
    vertical-align: top;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }
}