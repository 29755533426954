/* --- FOOTER --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';
@import '../../styles/tools/mixins';

.footer {
  background-color: $white-900;
  border: none;
  border-top: 1px solid $purple-10;
  float: left;
  margin: 0;
  padding: 30px 0 0;
  position: relative;
  vertical-align: top;
  width: 100%;

  &--bottom,
  &--credits {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--credits {
    .link {
      @include font($font-default, 9px, 500, $purple-100);
      float: left;
      position: relative;
      text-align: left;
      text-decoration: none;

      .image,
      .text {
        float: left;
        outline: none;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .image {
        height: auto;
        margin: 0 0 5px;
        width: 86px;
      }

      .text {
        @include font($font-default, 9px, 500, $black-100);
        line-height: 1em;
        padding: 0 0 0 5px;
      }
    }

    &.mobile {
      display: none;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .footer {
    &--credits {
      margin: 25px 0 0;

      &.mobile {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .footer {
    margin: 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .footer {
    margin: 0;
  }
}
