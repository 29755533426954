/* --- PRODUCT CREDITS FISIC CONTENT GALLERY CONTROLS --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.product-credits-fisic-content-controls {
  float: left;
  padding: 30px 10px 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  .btn,
  .label {
    float: left;
    position: relative;
    vertical-align: top;
  }

  .btn {
    border-color: $white-900;
    height: 25px;
    margin: 0 6px 0 0;
    padding: 0;
    transition: all 500ms $base-ease;
    width: 25px;

    .icon {
      @include rem('font-size', 9px);
      color: $white-900;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.close {
      float: right;
    }

    &:disabled {
      opacity: 0.6;
    }

    &:hover {
      background-color: $white-900;
      border-color: $white-900;

      .icon {
        color: $purple-900;
      }
    }
  }

  .label {
    @include font($font-default, 13px, 300, $white-900);
    margin: 5px 0 0 10px;

    strong {
      font-weight: 600;
      margin: 0 5px 0 0;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .product-credits-fisic-content-controls {
    padding: 30px 30px 0 25px;

    .btn {
      display: none;

      &.close {
        display: inline-block;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .product-credits-fisic-content-controls {
    padding: 30px 15px 0;
  }
}