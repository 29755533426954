/* --- CATEGORIES TAB CATEGORIES --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.categories-tab-categories {
  float: left;
  max-width: calc(100% - 665px);
  position: relative;
  width: 100%;
  vertical-align: top;
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {
  .categories-tab-categories {
    max-width: none;
    width: 40%;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .categories-tab-categories {
    width: 100%;
  }
}