/* --- CARROUSEL TIME LINE TIME --- */
@import '../../../styles/settings/colors';
@import '../../../styles/tools/mixins';

.carrousel {
  &-timeline {
    &-time {
      float: left;
      height: 30px;
      padding: 25px 15px;
      position: relative;
      width: 100%;
      vertical-align: top;

      &--item {
        float: left;
        position: relative;
        vertical-align: top;
        width: 100%;
        z-index: 2;
      }

      &--list {
        align-items: center;
        display: inline-flex;
        justify-content: space-between;
        flex-flow: row nowrap;
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;
        vertical-align: top;

        &:before {
          background-color: $white-700;
          content: '';
          height: 10px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          width: 100%;
          z-index: 1;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .carrousel {
    &-timeline {
      &-time {
        display: none;
      }
    }
  }
}