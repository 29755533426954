/* --- CARROUSEL TIMELINE CONTENT --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

.carrousel-timeline {
  &--content {
    float: left;
    margin: 0 15px;
    padding: 0;
    position: relative;
    width: calc(100% - 30px);
    vertical-align: top;

    &--list {
      float: left;
      height: 360px;
      position: relative;
      width: 100%;
      vertical-align: top;
    }
  }

  &:before {
    background-color: $white-900;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: calc(((100vw - #{$page-width}) / 2) + 15px);
    z-index: 2;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .carrousel-timeline {
    &:before,
    &:after {
      background-color: $white-900;
      content: '';
      height: 100%;
      position: absolute;
      width: 15px;
      z-index: 2;
    }
  
    &:after {
      right: 0;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .carrousel-timeline {
    &--content {
      margin: 0;
      text-align: center;
      width: 100%;

      &--list {
        float: none;
        height: 250px;
        overflow: hidden;
        text-align: left;
        width: calc(100% - ((60px + 25px) * 2));
      }
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .carrousel-timeline {
    &--content {
      &--list {
        height: 265px;
        width: 100%;
      }
    }
  }
}
