/* --- CARROUSEL TIMELINE TIME ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.carrousel {
  &-timeline {
    &-time {
      &-item {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;

        .year {
          @include font($font-default, 14px, 400, $purple-100);
          margin: 0;
          position: relative;
          text-align: center;
          top: 30px;
          width: 100%;
          vertical-align: top;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          transition: all 200ms $base-ease;
          top: 50%;
        }

        &:after {
          background-color: $purple-50;
          border: 5px solid $white-900;
          height: 20px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          z-index: 2;
        }

        &:before {
          background-color: $red-900;
          height: 2px;
          left: 0;
          transform: translate(0, -50%);
          width: 0;
          z-index: 1;
        }

        &[data-active="true"] {
          .year {
            color: $purple-900;
            font-weight: 600;
          }

          &:after {
            background-color: $purple-900;
            height: 28px;
          }

          &:before {
            width: 100%;
          }
        }

        &[data-current="true"] {
          &:before {
            transition-timing-function: linear;
            transition-delay: 200ms;
            width: 50%;
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .carrousel {
    &-timeline {
      &-time {
        &-item {
          &[data-active="true"] {
            .year {
              @include rem('font-size', 12px);
            }
          }
        }
      }
    }
  }
}
