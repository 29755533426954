/* --- FORMS --- */
@import './../settings/colors';
@import './../settings/fonts';
@import './../settings/grid';
@import './../tools/mixins';

@import './forms/reset';
@import './forms/buttons';
@import './forms/inputs';

@import './icons';
@import './animations';
@import './typography';

.form {
  float: left;
  margin: 50px 0 70px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--info,
  &--inputs {
    float: left;
    position: relative;

    &:before,
    &:after {
      content: '';
      height: 100%;
      position: absolute;
    }

    &:before {
      top: 0;
      width: calc((100vw - #{$page-width}) / 2);
    }

    &:after {
      background-color: $white-900;
      content: '';
      height: 3px;
      left: 0;
      top: 10px;
      width: 100%;
      z-index: 2;
    }
  }

  &--info {
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: flex-start;
    left: -15px;
    position: absolute;
    top: 0;
    width: calc(40% + 15px);
    
    &--background {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: calc(100% + 15px);
    }

    &--content {
      background-color: $red-900;
      position: relative;
      padding: 150px 30px 130px;
      text-align: left;
      width: calc(100% - 110px);

      .body,
      .title {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .body {
        @include font($font-default, 16px, 500, $white-900);
        line-height: 1.7em;

        p {
          margin: 5px 0;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }

      .title-main {
        @extend %title-1;
        color: $white-900;
        margin: 0 0 20px;

        &:before {
          background-color: $white-900;
        }
      }
    }

    &:before {
      background-color: $red-900;
      right: 100%;
    }
  }

  &--inputs {
    background-color: $purple-900;
    float: right;
    padding: 45px 15px 45px 65px;
    width: 60%;

    &:before {
      background-color: $purple-900;
      left: 100%;
    }

    &:after {
      transition: all 400ms $base-ease;
      top: 10px;
      width: calc(100% + ((100vw - #{$page-width}) / 2) + 15px);
    }
  }

  .btn {
    float: left;
    margin: 10px 0 15px;
    padding: 8px 25px;
    text-transform: capitalize;
  }

  .input-group {
    display: inline-flex;
    float: left;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 15px 0;
    position: relative;
    width: 100%;
    vertical-align: top;

    &.two {
      .input-group,
      .input-control {
        margin: 0;
        width: calc(50% - 15px);
      }
    }

    &.radios {
      bottom: -10px;
      align-items: center;
      left: 0;
      justify-content: flex-start;
      padding: 0 17px;
      position: absolute;
      width: 50%;

      &.r {
        left: 50%;
      }

      .input-control {
        margin: 0 20px 0 0;
        width: auto;
      }
    }

    &.join {
      text-align: left;

      .label {
        @include font($font-default, 11px, 600, $white-900);
        line-height: 1em;
        margin: 0 0 5px 7px;
        text-transform: uppercase;
        width: 100%;
        vertical-align: top;

        & + .input-control {
          margin-right: 5px;
          width: calc(30% - 5px);

          &[data-error="true"] {
            input {
              border-color: $red-900;
            }
          }
        }
      }

      .input-control {
        float: left;
        width: 70%;

        .label {
          display: none;
        }
      }
    }

    &[data-theme="white"] {
      .label {
        color: $black-900;
      }
    }
  }

  .input-message {
    float: left;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .message {
      float: left;
      padding: 0 0 0 20px;
      position: relative;
      width: 100%;
      vertical-align: top;

      .description,
      .title {
        float: left;
        margin: 0;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .description {
        @include font($font-default, 11px, 400, $green-100);
      }

      .title {
        @include font($font-default, 14px, 600, $green-100);
        margin: 0 0 5px;
      }

      &:before {
        @extend %icon;
        @extend %icon-check;
        @include rem('font-size', 10px);
        color: $green-100;
        left: 2px;
        position: absolute;
        top: 4px;
      }
    }
  }

  .wrapper {
    display: inline-block;
  }

  &[data-type="denounce"],
  &[data-type="contact"] {
    .form {
      &--info,
      &--inputs {
        width: 50%;
      }

      &--info {
        align-items: flex-start;
        justify-content: flex-end;
        left: 0;

        &--background {
          display: none;
        }

        &--content {
          background-color: $green-900;
          border-radius: 0 0 0 80px;
          max-width: 445px;
          padding: 70px 65px;
          width: 100%;

          .title-main {
            &:before {
              background-color: $white-100;
            }
          }
        }

        &:after,
        &:before {
          display: none;
        }
      }

      &--inputs {
        background-color: $white-700;
        padding: 45px 40px;

        form {
          float: left;
          max-width: 420px;
          width: 100%;
        }

        &:before {
          background-color: $white-700;
          height: calc(100% - 100px);
        }
      }
    }
  }

  &[data-type="denounce"] {
    .form {
      &--info {
        &--content {
          background-color: $red-400;

          .title-main {
            &:before {
              background-color: $purple-900;
            }
          }
        }
      }

      &--inputs {
        &:before,
        &:after {
          content: '';
        }
        
        &:before {
          box-sizing: border-box;
          background-color: rgba($white-900, 0.5);
          height: 100%;
          display: none;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
        }
      }
    }

    &[data-loading="true"] {
      .form {
        &--inputs {
          &:after {
            background-color: transparent;
            animation: loading 1s infinite linear;
            border-radius: 100%;
            border: 3px solid $red-900;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            height: 35px;
            left: 50%;
            top: 50%;
            transition: transform 500ms linear;
            transform-origin: left top;
            width: 35px;
          }

          &:before,
          &:after {
            display: inline-block;
          }
        }
      }
    }
  }

  &[data-error="true"] {
    .form {
      &--inputs {
        &:after {
          background-color: $red-900;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .form {
    &--info {
      &--content {
        padding: 90px 30px 70px;
        width: 90%;
      }
    }

    &--inputs {
      &:after {
        left: 0;
        width: 100%;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .form {
    &--info,
    &--inputs {
      left: initial;
      float: left;
      position: relative;
      top: initial;
      width: 100%;
    }

    &--info {
      background-color: $purple-900;
      justify-content: center;
      padding: 85px 110px 0;

      &--background {
        height: auto;
      }
      
      &--content {
        padding: 50px 25px 35px;
        width: 100%;
      }
    }

    &--inputs {
      padding: 40px 50px 50px;

      &:after {
        display: none;
      }
    }

    &[data-type="denounce"],
    &[data-type="contact"] {
      .form {
        &--info {
          background-color: transparent;
          padding: 0 0 0 40px;
  
          &--background {
            display: none;
          }
  
          &--content {
            max-width: none;
            padding: 70px 30px;

            .title-main {
              &:before {
                background-color: $white-100
              }
            }
          }
        }
  
        &--inputs {
          background-color: $white-700;
          padding: 40px 35px;
  
          form {
            max-width: none;
          }
  
          &:before {
            background-color: $white-700;
            height: 40px;
            left: 0;
            top: 100%;
            width: 200px;
          }

          &:after {
            display: inline-block;
          }
        }
      }

      .input-group {
        margin: 7px 0;
      }
    }

    .wrapper {
      padding: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .form {
    &--info {
      padding: 105px 15px 0;
    }

    &--inputs {
      padding: 40px 20px 50px;
    }

    &[data-type="denounce"],
    &[data-type="contact"] {
      .form {
        &--info,
        &--inputs {
          width: 100%;
        }

        &--info {
          padding: 0;
  
          &--background {
            display: none;
          }
  
          &--content {
            border-radius: 0;
            padding: 50px 30px 25px;

            .title-main {
              &:before {
                background-color: $white-100
              }
            }
          }
        }
  
        &--inputs {
          padding: 40px 30px;

          &:before {
            left: 0;
            height: 40px;
            top: 100%;
            width: 50px;
          }
        }
      }

      .input-group {
        margin: 7px 0;
      }
    }

    .btn {
      margin: 10px 0 30px;
    }

    .input-group {
      margin: 0 0 30px;

      &.two {
        margin: 0;

        .input-control {
          margin: 0 0 30px;
          width: 100%;
        }
      }

      &.radios {
        &.r {
          bottom: initial;
          left: initial;
          float: left;
          position: relative;
          top: -15px;
          width: 100%;
        }

        .input-control {
          margin: 0 0 20px;
          width: 50%;
        }
      }

      &.join {
        width: 100% !important;

        .input-control {
          &[data-type="phone"] {
            width: 70%;
          }
        }
      }

      .input-control {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}