/* --- MODAL --- */
@import '../../styles/settings/colors';
@import '../../styles/components/animations';

@import '../../styles/tools/mixins';

.modal {
  animation: slide-in-bck-center 800ms 0s $base-ease forwards;
  align-content: center;
  align-items: flex-end;
  background-color: rgba($black-700, 0.6);
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999999;

  &--body {
    max-width: 720px;
    position: relative;
    width: 100%;

    &--btn {
      background-color: transparent;
      border: none;
      left: 0;
      height: 100vh;
      outline: none;
      position: absolute;
      top: 0;
      width: 100vw;
    }
  }

  &.youtube {
    .modal {
      &--body {
        max-width: 640px;
      }
    }
  }
}

/* -- ANIMATION -- */
