/* --- WEBDOOR INFO --- */
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.webdoor {
  &--info {
    background-color: transparent;
    align-content: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    height: 100%;
    left: 0;
    float: left;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    vertical-align: top;
    z-index: 3;

    .slider {
      height: auto;
      width: auto;

      &--item {
        max-width: 700px;
        text-align: center;
        width: 100%;

        .btn {
          pointer-events: auto;
        }

        .title {
          @include font($font-default, 40px, 400, $white-900);
          display: block;
          margin: 0 auto 30px;
          line-height: 1.2em;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .webdoor {
    &--info {
      .slider {
        &--item {
          max-width: 280px;

          .title {
            @include rem('font-size', 22px);
            font-weight: 400;
            line-height: 1.4em;
          }
        }
      }
    }
  }
}
