/* --- BANK AS SERVICE HIGHLIGHT --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.bank-as-service-hightlight {
  float: left;
  position: relative;
  vertical-align: top;
  width: 100%;
  z-index: 2;

  &--content,
  &--item {
    float: left;
    position: relative;
    width: 100%;
  }

  &--content {
    align-items: stretch;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;

    &:before,
    &:after {
      background-color: $red-900;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    &:before {
      top: 20px;
    }

    &:after {
      bottom: 20px;
    }
  }

  &--item {
    align-items: flex-start;
    background-color: $purple-900;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 120px 0;

    .description,
    .image,
    .title {
      float: left;
      margin: 0;
      max-width: 305px;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .image {
      img {
        float: left;
        outline: none;
        position: relative;
        width: auto;
        vertical-align: top;
      }
    }

    .description {
      @include font($font-default, 16px, 500, $white-900);
      line-height: 1.7em;
    }

    .title {
      @include font($font-default, 34px, 500, $white-900);
      margin: 20px 0 10px;
      line-height: 1em;
    }

    &:nth-child(2) {
      background-color: $purple-700;
    }

    &:nth-child(3) {
      background-color: $purple-600;
    }
  }

  & + .read-more {
    z-index: 1;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .bank-as-service-hightlight {
    &--content {
      &:before {
        top: 10px;
      }

      &:after {
        bottom: 10px;
      }
    }
    
    &--item {
      justify-content: flex-start;
      padding: 45px 30px;

      .description {
        @include rem('font-size', 14px);
      }

      .image {
        width: 30px;

        img {
          height: auto;
          float: left;
          width: 100%;
        }
      }

      .title {
        @include rem('font-size', 20px);
        margin: 15px 0 10px;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .bank-as-service-hightlight {
    &--content {
      display: inline-block;
    }

    &--item {
      display: inline-block;
      padding: 30px 20px 30px 50px;

      .image {
        left: 20px;
        position: absolute;
        top: 28px;
        width: 20px;
      }

      .description,
      .title {
        width: 100%;
      }

      .title {
        margin: 0 0 10px;
      }
    }
  }
}