/* --- LIST HIGHLIGHTS CONTROLS --- */
@import '../../../../styles/settings/fonts';
@import '../../../../styles/tools/mixins';

.list-highlights {
  &--controls {
    right: 0;
    position: absolute;
    top: -90px;

    .btn {
      height: 30px;
      padding: 0;
      width: 30px;

      .icon {
        @include rem('font-size', 12px);
        color: $purple-900;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:first-child {
        margin-right: 5px;
      }

      &:disabled {
        opacity: 0.6;
      }
    }

    &[data-view="false"] {
      display: none;
    }
  }

  &[data-type="tipo3"] {
    .list-highlights {
      &--controls {
        top: -60px;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-highlights {
    &--controls {
      padding-right: 30px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-highlights {
    &--controls {
      display: none;
    }
  }
}
