/* --- MISSION VISION VALUES --- */
@import '../../styles//settings/settings';
@import '../../styles/tools/mixins';

.mission-vision-values {
  float: left;
  margin: 80px 0 60px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px 1px;
    padding: 0 20px;
    text-align: left;
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .mission-vision-values {
    margin: 80px 0 50px;

    .wrapper {
      grid-template-columns: 0.8fr 1fr;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .mission-vision-values {
    margin: 30px 0;

    .wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      padding: 0;
    }
  }
}
