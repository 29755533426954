/* --- FOOTER BOTTOM --- */
@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';
@import '../../../styles/tools/mixins';

.footer--bottom {
  border: none;
  border-top: 1px solid $purple-10;
  padding: 30px 15px 20px;
  text-align: center;

  &--list {
    display: inline-flex;
    flex-flow: row nowrap;
    float: left;
    margin: 0;
    list-style: none;
    padding: 0;
    position: relative;
    width: auto;
    vertical-align: top;

    &--item {
      float: left;
      margin: 2px 20px 0 0;
      position: relative;
      vertical-align: top;

      .link {
        @include font($font-default, 12px, 500, $purple-900);
      }

      @include hover-supported() {
        .link {
          text-decoration: none;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 1024px) {
  .footer--bottom {
    padding: 27px 40px 25px;

    .wrapper {
      padding: 0;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .footer--bottom {
    padding: 20px;

    &--list {
      align-items: center;
      display: inline-flex;
      justify-content: space-between;
      padding: 15px 0 0;
      width: 100%;

      &--item {
        float: none;
        margin: 0;
      }
    }
  }
}