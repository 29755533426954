/* --- QUOTE --- */
@import '../../styles/settings/settings';
@import '../../styles/tools/mixins';

.quote {
  background-color: $green-900;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--content,
  &--text,
  &--title {
    float: left;
    position: relative;
    width: 100%;
    vertical-align: top;
  }

  &--block {
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;

    .block {
      border: 2px solid $purple-10;
      border-bottom: none;
      border-right: none;
      bottom: 0;
      height: 108px;
      right: 0;
      position: absolute;
      width: calc(((100vw - #{$page-width}) / 2) + 165px);
      
      &:before {
        border: 2px solid $purple-10;
        border-right: none;
        border-bottom: none;
        bottom: 34px;
        content: '';
        height: 38px;
        left: initial;
        right: calc(((100vw - #{$page-width}) / 2) + 90px);
        position: absolute;
        width: 38px;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      border: 2px solid $red-900;
      border-left: none;
      border-top: none;
      border-radius: 0 0 75px 0;
      bottom: -32px;
      height: 1000px;
      right: calc(((100vw - #{$page-width}) / 2) + 90px);
      width: 1000px;
      z-index: 2;
    }

    &:after {
      border: none;
      border-bottom: 2px solid $purple-10;
      bottom: 32px;
      height: 0px;
      right: calc(((100vw - #{$page-width}) / 2) + 90px);
      width: 100%;
    }
  }

  &--content {
    float: none;
    max-width: 790px;
    padding: 100px 0;
    text-align: left;
  }

  &--text {
    @include font($font-default, 30px, 300, $white-900);
    line-height: 1.5em;
    margin: 0 0 20px;

    &:empty {
      display: none;
    }
  }

  &--title {
    .subtitle,
    .text {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;

      &:empty {
        display: none;
      }
    }

    .subtitle {
      @include font($font-default, 14px, 500, $white-900);
      margin: 5px 0 0;
    }

    .text {
      @include font($font-default, 26px, 500, $white-900);
      letter-spacing: -0.01em;
      line-height: 1.4em;

      &:before {
        background-color: $white-900;
        content: '';
        height: 2px;
        float: left;
        margin: 0 5px 0 0;
        position: relative;
        top: 17px;
        width: 15px;
      }
    }
  }

  &:after,
  &:before {
    content: '';
    left: 0;
    position: absolute;
  }

  &:after {
    background-color: $white-900;
    height: 5px;
    top: 10px;
    width: 100%;
  }

  &:before {
    background-color: rgba(#000, 0.1);
    height: 100%;
    top: 0;
    width: calc(((100vw - #{$page-width}) - 15px) / 2);
  }

  &[data-type="tipo2"] {
    background-color: $purple-600;

    &:after {
      display: none;
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .quote {
    &--block {
      .block {
        height: 103px;
        width: 105px;

        &:before {
          bottom: 28px;
          height: 39px;
          right: 32px;
          width: 40px;
        }
      }

      &:before {
        border-radius: 0 0 55px;
        right: 32px;
      }

      &:after {
        bottom: 28px;
        right: 35px;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .quote {
    &--content {
      padding: 75px 60px;
    }

    &--text {
      @include rem('font-size', 18px);
    }

    &--title {
      .text {
        @include rem('font-size', 18px);

        &:before {
          top: 12px;
        }
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .quote {
    &--block {
      .block {
        border-width: 1px;
        height: 45px;
        width: 42px;

        &:before {
          border-width: 1px;
          bottom: 14px;
          height: 16px;
          right: 12px;
          width: 16px;
        }
      }

      &:before {
        border-width: 1px;
        border-radius: 0 0 40px;
        right: 12px;
      }

      &:after {
        border-width: 1px;
        bottom: 14px;
        right: 12px;
      }
    }

    &--content {
      padding: 55px 0;
    }
  }
}


