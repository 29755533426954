/* --- LIST CARROUSEL PHOTO ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-carrousel-photo-item {
  height: 100%;
  overflow: hidden;
  padding: 0;
  user-select: none;
  position: absolute;
  width: 100%;
  will-change: transform;

  &--info {
    float: left;
    height: calc(100% - 40px);
    position: relative;
    vertical-align: top;
    width: 100%;

    .image,
    .text {
      float: left;
      pointer-events: none;
      position: relative;
      width: 100%;
      vertical-align: top;
      user-select: none;
    }

    .image {
      align-items: center;
      display: inline-flex;
      flex-flow: row wrap;
      justify-content: center;
      height: 100%;
      overflow: hidden;

      img {
        height: auto;
        float: left;
        position: relative;
        width: auto;
        vertical-align: top;
      }
    }

    .text {
      @include font($font-default, 12px, 500, $purple-100);
      border: none;
      border-bottom: 1px solid $purple-100;
      line-height: 1.7em;
      height: 40px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px 0;
      white-space: nowrap;

      &:empty {
        display: none;
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-photo-item {
    &--info {
      .image {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-carrousel-photo-item {
    &--info {
      .image {
        
      }
    }
  }
}