/* --- LIST STATIC --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';
@import '../../../../styles/settings/grid';

@import '../../../../styles/components/typography';

@import '../../../../styles/tools/mixins';

.list-static {
  &--item {
    float: left;
    padding: 0 20px 50px;
    position: relative;
    width: calc(100% / 5);
    vertical-align: top;

    .block-icon,
    .description,
    .link,
    .title {
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    .block-icon {
      margin: 0 0 20px;
      padding: 0 0 20px;
      text-align: center;

      .icone {
        position: relative;
        top: -5px;

        img {
          height: auto;
          position: relative;
          width: 50px;
        }
      }

      &:before {
        background-color: transparent;
        border: 1px solid $red-900;
        bottom: 6px;
        border-radius: 0px 0px 0px 30px;
        content: '';
        left: 50%;
        height: 55px;
        position: absolute;
        transform: translate(-50%, 0);
        width: 62px;
      }
    }

    .description {
      @include font($font-default, 13px, 300, $white-900);
      line-height: 1.5em;
      margin: 20px 0 10px;
    }

    .link {
      @include font($font-default, 12px, 300, $white-900);
      margin: 5px 0 0;
      position: relative;
    }

    .title {
      @include font($font-default, 16px, 400, $white-900);
      line-height: 1.4em;

      &:empty {
        display: none;
      }
    }

    &:before {
      border: 1px solid $red-900;
      border-right: none;
      border-top: none;
      content: '';
      height: 13px;
      left: 0;
      position: absolute;
      top: calc(100% - 35px);
      width: 24px;
    }
  }

  &[data-type="tipo2"] {
    .list-static {
      &--item {
        .block-icon {
          &:before {
            background-color: $red-800;
          }
        }

        &:before {
          border-color: $purple-900;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-static {
    &--item {
      margin: 0 0 40px;
      padding: 40px 55px 0;
      width: 100%;

      .block-icon {
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 26px;
        width: 40px;

        .icone {
          left: 50%;
          height: 50px;
          transform: translate(-50%, 0);
          width: 50px;

          img {
            width: 35px;
          }
        }

        &:before {
          height: 36px;
          width: 40px;
        }
      }

      .description {
        @include rem('font-size', 12px);
        margin: 10px 0;
      }

      .link {
        bottom: initial;
        left: initial;
        right: initial;
        position: relative;
      }

      .title {
        @include rem('font-size', 14px);
      }

      &:before {
        left: 40px;
        top: calc(100% + 10px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-static {
    &--item {
      margin: 0 0 50px;
      padding: 13px 0 30px 55px;
      width: 100%;

      .block-icon {
        top: 0;
      }

      &:before {
        top: 100%;
      }
    }
  }
}
