/* -- MAIN --- */
@import '../../styles/main.scss';

.main {
  float: left;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 100vw;
}