/* --- HIGHLIGHTS LIST --- */

.list-highlights {
  &--list {
    float: left;
    padding: 40px 20px;
    position: relative;
    text-align: left;
    vertical-align: top;
    width: 100%;
    user-select: none;
  }

  &[data-type="tipo3"] {
    .list-highlights {
      &--list {
        padding: 0 20px;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-highlights {    
    &--list {
      padding: 25px 0;
      z-index: 2;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-highlights {
    &[data-type="tipo3"] {
      .list-highlights {
        &--list {
          padding: 0;
        }
      }
    }
  }
}


/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .list-highlights {
    &--list {
      padding: 20px 0 0;
    }
  }
}
