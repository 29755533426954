/* --- CATEGORIES TAB CATEGORIES LIST --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.categories-tab-categories-list {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0 30px 0 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  &--item {
    float: left;
    margin: 0 0 30px;
    position: relative;
    width: 100%;
    vertical-align: top;
  }
}

/* --- QUERIES --- */
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .categories-tab-categories-list {
    padding: 0;
  }
}