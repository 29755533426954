/* --- PRODUCT CREDITS JURIDIC --- */
@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.product-credits-juridic {
  float: left;
  margin: 0 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
  vertical-align: top;

  &--title {
    max-width: 790px;
    position: relative;
    text-align: left;
    width: 100%;
    vertical-align: top;

    .text {
      @include font($font-default, 22px, 500, $black-700);
      margin: 40px 0;
      line-height: 1.4em;
      vertical-align: top;

      &:empty {
        display: none;
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-juridic {
    margin: 0 0 40px;

    &--title {
      padding: 0 30px;
      
      .text {
        margin: 20px 0;
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .product-credits-juridic {
    &--title {
      padding: 0 20px;
    }
  }
}