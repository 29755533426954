/* --- PRODUCT CREDITS FISIC ITEM --- */
@import '../../../styles/settings/colors';
@import '../../../styles/components/animations';

.product-credits-fisic-item {
  background-color: transparent;
  max-width: 730px;
  position: relative;
  text-align: left;
  width: 100%;
  vertical-align: top;

  &--info {
    background-color: $white-700;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
    position: relative;
    transition: all 400ms $base-ease;

    .product-credits-fisic-item--select--form {
      .select {
        &-title {
          border: 1px solid transparent;
          transition: all 400ms $base-ease;
        }
      }
    }

    &:hover {
      .product-credits-fisic-item--select--form {
        .select {
          &-title {
            border: 1px solid $purple-900;

            &--content {
              .text {
                color: $purple-900;
              }
            }
          }
        }
      }
    }
  }

  &[data-active="true"] {
    .product-credits-fisic-item {
      &--info {
        border-color: $purple-900;
        box-shadow: 0px 14px 26px rgba(0, 0, 0, 0.16);
      }
      
      &--select {
        &--form {
          .select-title {
            border-color: $purple-900;

            &--content {
              .text {
                color: $purple-900;
              }
            }
          }
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .product-credits-fisic-item {
    max-width: 570px;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .product-credits-fisic-item {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
}
