/* --- HIGHLIGHTS ITEM --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/grid';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

@import '../../../../styles/components/animations';

.list-highlights {
  &--item {
    float: left;
    margin: 0;
    outline: none;
    padding: 30px 29px 80px;
    position: relative;
    text-decoration: none;
    text-align: left;
    width: calc((#{$page-width} - 40px) / 4);
    vertical-align: top;

    .description,
    .icon,
    .name {
      float: left;
      margin: 0;
      position: relative;
      z-index: 2;
    }

    .description {
      @include font($font-default, 13px, 300, $purple-100);
      line-height: 1.8em;
    }

    .icon {
      margin: 0 0 10px;
      width: 100%;

      .graph,
      .number {
        float: left;
        margin: 0;
        padding: 0;
        position: relative;
        vertical-align: top;
      }

      .graph {
        height: 31px;
        width: auto;
      }

      .number {
        border: 1px solid $red-900;
        border-radius: 100%;
        height: 30px;
        margin: 0 0 5px;
        width: 30px;

        .text {
          @include font($font-default, 18px, 400, $white-900);
          left: 50%;
          line-height: 1em;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .name {
      @include font($font-default, 18px, 600, $black-750);
      line-height: 1.4em;
      margin: 0 0 10px;
    }

    &:before {
      background-color: $white-700;
      border-radius: 8px;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 400ms $base-ease;
      width: calc(100% - 18px);
      z-index: 1;
    }

    &:hover {
      .name {
        color: $purple-900;
      }
      
      &:before {
        background-color: $white-800;
      }
    }

    &[data-type="tipo2"] {
      .description,
      .name {
        color: $white-900;
      }

      &:before {
        background-color: $purple-900;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .list-highlights {
    &--item {
      width: calc(#{$page-width} / 5);
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-highlights {
    &--item {
      width: calc((#{$page-width} - 40px) / 5);

      .description {
        @include rem('font-size', 12px);
      }

      .name {
        @include rem('font-size', 14px);
      }

      &:before {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .list-highlights {
    &--item {
      width: calc(60vw);
    }
  }
}