/* --- BUTTONS --- */
@import "../../settings/settings";
@import "../../tools/mixins";

@import '../../components/animations';

%btn-clear {
  @include box-display;
  border: none;
  background: transparent;
  outline: none;
  position: relative;
  transition: all 300ms $base-ease;
}

.btn {
  @extend %btn-clear;
  cursor: pointer;
  text-decoration: none;

  &-border {
    @include font($font-default, 13px, 600, $black-900);
    box-sizing: border-box;
    border: 2px solid $black-900;
    border-radius: 4px;
    line-height: 1em;
    padding: 9px 15px;
    
    &.w {
      border-color: $white-900;
      color: $white-900;

      &:hover {
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
    
    &.t {
      background-color: $white-900;
      border-color: $purple-50;
      color: $purple-900;

      &:hover {
        border-color: $purple-550;
        color: $purple-550;
      }
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  &-control {
    border: 2px solid $purple-50;
    border-radius: 4px;
    overflow: hidden;
    height: 32px;
    outline: none;
    text-align: center;
  }

  &-default {
    @include font($font-default, 13px, 500, $white-900);
    background-color: $purple-900;
    border-radius: 4px;
    padding: 8px 15px;

    &.b {
      @include rem('font-size', 13px);
      font-weight: 600;
      height: 40px;
      padding: 10px 40px;
    }

    &.d {
      background-color: $red-900;
      color: $white-900;

      &:hover {
        background-color: $red-550;
        color: $white-900;
      }
    }

    &:hover {
      background-color: $purple-550;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .btn {
    &-default {
      &.b {
        padding: 10px 20px;
      }
    }
  }
}