/* --- LIST CARROUSEL PHOTO CONTROLS --- */
@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-carrousel-photo-controls {
  left: 50%;
  height: calc(100% - 40px);
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: calc(100% + (90px * 2) + (5px * 2));
  vertical-align: top;
  z-index: 2;

  .btn {
    background-color: $red-900;
    border-radius: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 90px;

    .icon {
      @include rem('font-size', 20px);
      color: $white-900;
    }

    &:last-child {
      left: initial;
      right: 0;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-carrousel-photo-controls {
    width: calc(100% + (60px * 2) + (15px * 2));

    .btn {
      top: 50%;
      transform: translate(0, -50%);
      width: 60px;

      .icon {
        @include rem('font-size', 15px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-carrousel-photo-controls {
    width: 100%;

    .btn {
      background-color: rgba($black-900, 0.8);
      height: 50px;
      width: 40px;
    }
  }
}