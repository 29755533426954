/* --- TAB SUBMENU CONTENT --- */
@import '../../../styles/settings/settings';
@import '../../../styles/tools/mixins';

@import '../../../styles/components/animations';

.tab-submenu {
  &--list {
    list-style: none;
    margin: 10px 0 0;
    padding: 20px 0;
    width: 100%;
    vertical-align: top;
  }
  
  &--item {
    margin: 0 3px;
    
    .btn {
      &.btn-default {
        background-color: $white-700;
        color: $black-100;
        padding: 12px 15px;
        transition: all 400ms $base-ease;

        &.d {
          &:hover {
            background-color: $white-700;
            color: $red-900;
          }
        }
      }

      &.active {
        background-color: $red-900;
        color: $white-900;
      }
    }

    &[data-remove="true"] {
      display: none;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .tab-submenu {
    &--list {
      display: inline-flex;
      width: auto;
      flex-flow: row nowrap;
      overflow-x: auto;
      justify-content: space-between;
    }

    &--item {
      .btn {
        white-space: nowrap;
      }
    }
  }
}
