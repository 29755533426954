/* --- TYPOGRAPHY --- */
@import './../components/animations';
@import './../settings/settings';
@import './../tools/mixins';

%title-1 {
  @include box-display;
  @include font($font-default, 34px, 500, $black-900);
  line-height: 1.3em;
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  vertical-align: top;

  &:before {
    @include box-display;
    background-color: $black-900;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    top: -20px;
    width: 35px;
  }
}

%text {
  @include box-display;
  @include font($font-default, 16px, 300, $black-900);
  line-height: 1.7em;
}

.link-default {
  @include box-display;
  @include font($font-default, 13px, 400, $purple-70);
  float: left;
  line-height: 1em;
  height: 100%;
  padding: 0 15px;
  position: relative;
  text-decoration: none;
  transition: all 400ms $base-ease;
  width: 100%;
  vertical-align: top;
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  %title-1 {
    font-size: 22px;

    &:before {
      width: 25px;
    }
  }

  %text {
    font-size: 14px;
  }
}